@import './variables';
@import './../../assets/images/images';
.ngx-select *,
* {
  box-sizing: border-box;
  outline: none;
}

.input-group {
  .input-group-text {
    padding: 15px 18px;
  }
}

.ngx-select {
  height: 56px;
  outline: 0;

  .form-control:focus {
    box-shadow: none;
  }

  .ngx-select__selected {
    .ngx-select__toggle {
      border-color: $input_border_color;
      height: 56px;
      padding: 16px 18px;

      &:hover {
        background-color: $input_hover_bg_color;

        .ngx-select__placeholder {
          color: $input_placeholder_color_hover;
        }
      }

      .dropdown-toggle {
        &:after {
          display: none;
        }
        @extend .icon;
        @extend .icon-expand_more;
      }

      .ngx-select__placeholder {
        color: $input_placeholder_color;
      }
    }
  }

  .ngx-select__search {
    height: 56px;
  }

  // Dropdown list
  .ngx-select__choices {
    top: 0;
    padding: 8px 0;
    box-shadow: 0px 8px 20px rgba(2, 4, 16, 0.14);
    max-height: 239px;
    border: none;

    .ngx-select__item-group {
      padding: 0;
      background: none;

      .ngx-select__item {
        padding: 16px;
        line-height: 40px;
        color: $mirage;
        background: $dropdown_bg;
        border-radius: 1px;

        &:hover {
          background-color: $dropdown_item_bg_hover;
        }
      }
    }
  }
}

ngx-select {
  &.w-100 {
    .ngx-select {
      width: 100%
    }
  }
  &.small {
    .ngx-select {
      font-size: 12px;
      line-height: 12px;
      height: auto;
      display: inline-block;
    }

    .ngx-select__search {
      height: 30px !important;
      font-size: 12px;
      line-height: 12px;
      margin-bottom: 5px;
      margin-left: 10px;

    }

    .ngx-select__selected {
      .ngx-select__toggle {
        padding: 8px;
        height: 30px;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        min-width: 130px;

        .dropdown-toggle {
          font-size: 20px;
        }
      }

      .ngx-select__selected-plural {
        border-radius: 16px;
        border: none;
        font-size: 12px;
        padding: 5px 10px;
        line-height: 12px;
        background: #E6D2F8;
        color: rgba(23, 27, 44, 0.8);

        .ngx-select__clear {
          background-color: $black_pearl;
          color: $white;
          border-radius: 50%;
          padding: 3px;
          font-size: 10px;
          font-weight: 100;
        }
      }
    }

    &.selected_outside .ngx-select {
      padding: 0;
      position: relative;

      .ngx-select__search::placeholder {
        color: $black_pearl;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
      }

      .ngx-select__selected {
        position: absolute;
        right: 5px;
        top: 5px;
        @extend .icon;
        @extend .icon-expand_more;

        font-size: 20px !important;

        span {
          display: none;
        }
      }

      .ngx-select__search {
        margin-bottom: 0;

        ::placeholder {
          color: $black_pearl !important;;
        }
      }
    }

    .ngx-select__choices {
      .ngx-select__item-group .ngx-select__item {
          padding: 8px;
          line-height: 18pt;
      }
    }
  }

  &.medium {
    .ngx-select {
      height: 40px;
      display: inline-block;
    }

    .ngx-select__search {
      height: 40px;
    }

    .ngx-select__selected {
      .ngx-select__toggle {
        padding: 12px;
        height: 40px;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        min-width: 130px;

        .dropdown-toggle {
          font-size: 20px;
        }
      }
    }
    .ngx-select__choices {
      .ngx-select__item-group .ngx-select__item {
          padding: 8px;
          line-height: 18pt;
      }
    }
  }

  &.break-spaces {
    .ngx-select__item {
      white-space: break-spaces;
    }
  }
}
