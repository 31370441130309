.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.tileTooltipContainer.show {
  left: auto !important;
  right: 20px !important;
  top: 60px !important;
  opacity: 1;

  .tooltip-arrow {
    display: none;
  }

  .tooltip-inner {
    max-width: none;
    background-color: transparent;
  }
}

bs-tooltip-container.nowrap {
  @include media-breakpoint-up(sm) {
    white-space: nowrap;
    .tooltip-inner {
      max-width: none;
    }
  }
}
