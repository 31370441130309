.pagination-container {
	margin-bottom:200px;
	.pagination {
	    display: flex;
	    flex-direction: row;
	    flex-wrap: nowrap;
	    justify-content: center;
	    align-content: stretch;
	    align-items: center;
	}
	.page-button {
		width:30px;
		height:30px;
		display:block;
		background-color:$white;
		border-radius:6px;
		border:0 solid transparent;
		color:$greytext;
		padding:0;
		line-height:30px;
		@include transitionfixed;
		&:hover {
			background-color:$midgrey;
			color:$white;
		}
	}
	.previous {
		position:relative;
		&:before {
			left:12px;
			top:10px;
			content:"";
			display:block;
			width:8px;
			height:8px;
			border-left:2px solid $greytext;
			border-top:2px solid $greytext;
			position:absolute;
			transform: rotate(-45deg);
			@include transitionfixed;
		}
		&:hover {
			&:before {
				border-left:2px solid $white;
				border-top:2px solid $white;
			}
		}
	}
	.numbers {
		margin: 0 19px;
	    display: flex;
	    flex-direction: row;
	    flex-wrap: nowrap;
	    justify-content: center;
	    align-content: stretch;
	    align-items: center;
	    background-color: $white;
	    border-radius:6px;
	}
	.mobile-placeholder {
		display:none;
	}
	.placeholder, .mobile-placeholder {
		color:$greytext;
		padding:0px 10px;
	}
	.active {
		background-color:$midgrey;
		color:$white;
	}
	.last {
	}
	.next {
		position:relative;
		&:before {
			left:8px;
			top:10px;
			content:"";
			display:block;
			width:8px;
			height:8px;
			border-right:2px solid $greytext;
			border-top:2px solid $greytext;
			position:absolute;
			transform: rotate(45deg);
			@include transitionfixed;
		}
		&:hover {
			&:before {
				border-right:2px solid $white;
				border-top:2px solid $white;
			}
		}
	}
	@include inverse-breakpoint('50em') {
		.mobile-placeholder, .placeholder {
			display:block;
			padding:0px 0px;
		}
		.page-button {
			display:none;
			&:first-of-type, &:last-of-type, &.active {
				display:block;

			}
		}
	}
}
