@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import './variables';

@mixin desktop-responsiveness() {
  @include media-breakpoint-up(sm) {
    @content;
  }
}

@mixin mobile-responsiveness() {
  @include media-breakpoint-down(sm) {
    @content;
  }
}

.on-boarding-modal {
  @include desktop-responsiveness() {
    width: 400px;
  }

  @include mobile-responsiveness() {
    max-width: none;
    height: 100%;
    min-height: 0;
  }

  .modal-content {
    border: none;
  }
}

.vantage-on-boarding.shepherd-element {
  padding: 24px;
  border-radius: 4px;
  background-color: #171b2c;
  box-shadow: 0px -7px 18px rgba(2, 4, 16, 0.08);
  width: 100%;
  opacity: 0;
  outline: none;
  transition: opacity 0.3s, visibility 0.3s;
  visibility: hidden;
  z-index: 9999;

  @include desktop-responsiveness() {
    max-width: 400px;
  }

  &,
  &:after,
  &:before,
  * {
    box-sizing: border-box;
  }

  &.shepherd-enabled {
    opacity: 1;
    visibility: visible;
  }

  &[data-popper-reference-hidden] {
    &:not(.shepherd-centered) {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
  }

  .shepherd-arrow {
    position: absolute;
    width: 16px;
    height: 16px;
    z-index: -1;

    &:before {
      position: absolute;
      width: 16px;
      height: 16px;
      z-index: -1;
      content: '';
      transform: rotate(45deg);
      background-color: #171b2c;
    }
  }

  .shepherd-content {
    .shepherd-cancel-icon {
      width: 12px;
      height: 12px;
      color: #d1d1d5;
      background: transparent;
      border: none;
      font-size: 26px;
      cursor: pointer;
      font-weight: 400;
      margin: 0;
      padding: 0;
      transition: color 0.5s ease;

      span {
        margin-left: -3px;
        margin-top: -4px;
      }
    }

    .shepherd-header {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 16px;

      .shepherd-title {
        display: flex;
        flex: 1 0 auto;
        margin: auto;
        padding: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #fff;
      }
    }

    .shepherd-text {
      margin-bottom: 40px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.8);

      p {
        margin-top: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .shepherd-footer {
      display: flex;
      justify-content: flex-end;

      .pagination-wrapper {
        display: flex;
        margin: auto auto 0 0;
        max-height: 6px;

        [class^='pagination-step'] {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          margin-right: 6px;
          background-color: rgba(255, 255, 255, 0.24);
        }

        .pagination-step-active {
          background-color: #fff;
        }
      }

      .shepherd-button {
        padding-left: 25px;
        padding-right: 25px;
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.shepherd-element[data-popper-placement^='top'] {
  > .shepherd-arrow {
    bottom: -8px;
  }
}

.shepherd-element[data-popper-placement^='bottom'] {
  > .shepherd-arrow {
    top: -8px;
  }
}

.shepherd-element[data-popper-placement^='left'] {
  > .shepherd-arrow {
    right: -8px;
  }
}

.shepherd-element[data-popper-placement^='right'] {
  > .shepherd-arrow {
    left: -8px;
  }
}

.shepherd-element.shepherd-centered {
  > .shepherd-arrow {
    opacity: 0;
  }
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target {
  &,
  * {
    pointer-events: none;
  }
}

.shepherd-modal-overlay-container {
  -ms-filter: progid:dximagetransform.microsoft.gradient.alpha(Opacity=50);
  filter: alpha(opacity=50);
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: auto;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-out, height 0ms 0.3s, opacity 0.3s 0ms;
  width: 100vw;
  z-index: 9997;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: 0;
  transition: all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s;
}

.shepherd-element.vantage-on-boarding {
  // Step insights cards
  &.step-insight-cards {
    @include desktop-responsiveness() {
      margin: 0 0 -60px 100px !important;
    }
  }

  // Step periods
  // &.step-periods {
  //   top: 10px !important;
  // }

  // Step create collection
  // &.step-create-collection {
  //   bottom: 40px !important;
  // }

  // Step add collection
  &.step-add-card {
    right: 15px !important;
  }

  // Step share card
  &.step-share-card {
    margin-top: 15px !important;
  }

  // Step apps
  &.step-apps {
    @include desktop-responsiveness {
      margin-left: 15px !important;
    }
  }

  // Step assistant
  &.step-assistant {
    margin-right: 15px !important;
  }
}

button.visible {
  visibility: visible !important;
  background-color: $button_text_focus_bg;
}

.hover-effect-icon-btn {
  background: $card_header_btn_hover_bg;
  cursor: pointer;

  .icon:before {
    color: $card_header_btn_hover_color;
  }
}

.hover-effect-btn {
  background-color: $navigation_hover_bg;
}
