.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1070;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .collapsing,
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: '';
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
  background: #171b2c;
  mix-blend-mode: normal;
  opacity: 0.66;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  justify-content: space-between;
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f9fc;
  font-family: Inter;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  src: url(/assets/fonts/Inter-Thin-BETA.woff2) format('woff2'),
    url(/assets/fonts/Inter-Thin-BETA.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  src: url(/assets/fonts/Inter-ThinItalic-BETA.woff2) format('woff2'),
    url(/assets/fonts/Inter-ThinItalic-BETA.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  src: url(/assets/fonts/Inter-ExtraLight-BETA.woff2) format('woff2'),
    url(/assets/fonts/Inter-ExtraLight-BETA.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  src: url(/assets/fonts/Inter-ExtraLightItalic-BETA.woff2) format('woff2'),
    url(/assets/fonts/Inter-ExtraLightItalic-BETA.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  src: url(/assets/fonts/Inter-Light-BETA.woff2) format('woff2'),
    url(/assets/fonts/Inter-Light-BETA.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  src: url(/assets/fonts/Inter-LightItalic-BETA.woff2) format('woff2'),
    url(/assets/fonts/Inter-LightItalic-BETA.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/Inter-Regular.woff2) format('woff2'),
    url(/assets/fonts/Inter-Regular.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  src: url(/assets/fonts/Inter-Italic.woff2) format('woff2'),
    url(/assets/fonts/Inter-Italic.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url(/assets/fonts/Inter-Medium.woff2) format('woff2'),
    url(/assets/fonts/Inter-Medium.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  src: url(/assets/fonts/Inter-MediumItalic.woff2) format('woff2'),
    url(/assets/fonts/Inter-MediumItalic.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: url(/assets/fonts/Inter-SemiBold.woff2) format('woff2'),
    url(/assets/fonts/Inter-SemiBold.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  src: url(/assets/fonts/Inter-SemiBoldItalic.woff2) format('woff2'),
    url(/assets/fonts/Inter-SemiBoldItalic.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: url(/assets/fonts/Inter-Bold.woff2) format('woff2'),
    url(/assets/fonts/Inter-Bold.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  src: url(/assets/fonts/Inter-BoldItalic.woff2) format('woff2'),
    url(/assets/fonts/Inter-BoldItalic.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  src: url(/assets/fonts/Inter-ExtraBold.woff2) format('woff2'),
    url(/assets/fonts/Inter-ExtraBold.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  src: url(/assets/fonts/Inter-ExtraBoldItalic.woff2) format('woff2'),
    url(/assets/fonts/Inter-ExtraBoldItalic.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  src: url(/assets/fonts/Inter-Black.woff2) format('woff2'),
    url(/assets/fonts/Inter-Black.woff) format('woff');
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  src: url(/assets/fonts/Inter-BlackItalic.woff2) format('woff2'),
    url(/assets/fonts/Inter-BlackItalic.woff) format('woff');
}

[aria-label][role~='tooltip'] {
  position: relative;
}

[aria-label][role~='tooltip']::after,
[aria-label][role~='tooltip']::before {
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  opacity: 0;
  pointer-events: none;
  transition: all var(--microtip-transition-duration, 0.18s)
    var(--microtip-transition-easing, ease-in-out)
    var(--microtip-transition-delay, 0s);
  position: absolute;
  box-sizing: border-box;
  z-index: 10;
  transform-origin: top;
}

[aria-label][role~='tooltip']::before {
  background-size: 100% auto !important;
  content: '';
}

[aria-label][role~='tooltip']::after {
  background: rgba(17, 17, 17, 0.9);
  border-radius: 4px;
  color: #fff;
  content: attr(aria-label);
  font-size: var(--microtip-font-size, 13px);
  font-weight: var(--microtip-font-weight, normal);
  text-transform: var(--microtip-text-transform, none);
  padding: 0.5em 1em;
  white-space: nowrap;
  box-sizing: content-box;
}

[aria-label][role~='tooltip']:focus::after,
[aria-label][role~='tooltip']:focus::before,
[aria-label][role~='tooltip']:hover::after,
[aria-label][role~='tooltip']:hover::before {
  opacity: 1;
  pointer-events: auto;
}

[role~='tooltip'][data-microtip-position|='top']::before {
  background: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%280%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E')
    no-repeat;
  height: 6px;
  width: 18px;
  margin-bottom: 5px;
  transform: translate3d(-50%, 0, 0);
  bottom: 100%;
  left: 50%;
}

[role~='tooltip'][data-microtip-position|='top']::after {
  margin-bottom: 11px;
  transform: translate3d(-50%, 0, 0);
  bottom: 100%;
  left: 50%;
}

[role~='tooltip'][data-microtip-position='top']:hover::after,
[role~='tooltip'][data-microtip-position|='top']:hover::before {
  transform: translate3d(-50%, -5px, 0);
}

[role~='tooltip'][data-microtip-position='top-left']::after {
  transform: translate3d(calc(-100% + 16px), 0, 0);
  bottom: 100%;
}

[role~='tooltip'][data-microtip-position='top-left']:hover::after {
  transform: translate3d(calc(-100% + 16px), -5px, 0);
}

[role~='tooltip'][data-microtip-position='top-right']::after {
  transform: translate3d(calc(0px + -16px), 0, 0);
  bottom: 100%;
}

[role~='tooltip'][data-microtip-position='top-right']:hover::after {
  transform: translate3d(calc(0px + -16px), -5px, 0);
}

[role~='tooltip'][data-microtip-position|='bottom']::before {
  background: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28180%2018%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E')
    no-repeat;
  height: 6px;
  width: 18px;
  margin-top: 5px;
  margin-bottom: 0;
  transform: translate3d(-50%, -10px, 0);
  bottom: auto;
  left: 50%;
  top: 100%;
}

[role~='tooltip'][data-microtip-position|='bottom']::after {
  margin-top: 11px;
  transform: translate3d(-50%, -10px, 0);
  top: 100%;
  left: 50%;
}

[role~='tooltip'][data-microtip-position='bottom']:hover::after,
[role~='tooltip'][data-microtip-position|='bottom']:hover::before {
  transform: translate3d(-50%, 0, 0);
}

[role~='tooltip'][data-microtip-position='bottom-left']::after {
  transform: translate3d(calc(-100% + 16px), -10px, 0);
  top: 100%;
}

[role~='tooltip'][data-microtip-position='bottom-left']:hover::after {
  transform: translate3d(calc(-100% + 16px), 0, 0);
}

[role~='tooltip'][data-microtip-position='bottom-right']::after {
  transform: translate3d(calc(0px + -16px), -10px, 0);
  top: 100%;
}

[role~='tooltip'][data-microtip-position='bottom-right']:hover::after {
  transform: translate3d(calc(0px + -16px), 0, 0);
}

[role~='tooltip'][data-microtip-position='left']::after,
[role~='tooltip'][data-microtip-position='left']::before {
  bottom: auto;
  left: auto;
  right: 100%;
  top: 50%;
  transform: translate3d(10px, -50%, 0);
}

[role~='tooltip'][data-microtip-position='left']::before {
  background: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28-90%2018%2018%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E')
    no-repeat;
  height: 18px;
  width: 6px;
  margin-right: 5px;
  margin-bottom: 0;
}

[role~='tooltip'][data-microtip-position='left']::after {
  margin-right: 11px;
}

[role~='tooltip'][data-microtip-position='left']:hover::after,
[role~='tooltip'][data-microtip-position='left']:hover::before,
[role~='tooltip'][data-microtip-position='right']:hover::after,
[role~='tooltip'][data-microtip-position='right']:hover::before {
  transform: translate3d(0, -50%, 0);
}

[role~='tooltip'][data-microtip-position='right']::after,
[role~='tooltip'][data-microtip-position='right']::before {
  bottom: auto;
  left: 100%;
  top: 50%;
  transform: translate3d(-10px, -50%, 0);
}

[role~='tooltip'][data-microtip-position='right']::before {
  background: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%2890%206%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E')
    no-repeat;
  height: 18px;
  width: 6px;
  margin-bottom: 0;
  margin-left: 5px;
}

[role~='tooltip'][data-microtip-position='right']::after {
  margin-left: 11px;
}

[role~='tooltip'][data-microtip-size='small']::after {
  white-space: initial;
  width: 80px;
}

[role~='tooltip'][data-microtip-size='medium']::after {
  white-space: initial;
  width: 150px;
}

[role~='tooltip'][data-microtip-size='large']::after {
  white-space: initial;
  width: 260px;
}

vp-side-nav.open + section.page {
  @import 'bootstrap/scss/functions';
  @import 'bootstrap/scss/variables';
  @import 'bootstrap/scss/mixins';

  @include media-breakpoint-up(lg) {
    margin-left: 320px;
  }
}

:root {
  --microtip-transition-duration: 0.1s;
  --microtip-transition-delay: 0.5s;
  --microtip-transition-easing: ease-out;
  --microtip-font-size: 14px;
  --microtip-font-weight: 400;
  --microtip-text-transform: none;
}

button {
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 14px;
  text-transform: uppercase;
  font-feature-settings: 'tnum' on, 'lnum' on;
  border: 1px solid #2534f7;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  background: #2534f7;
  color: #fff;
}

button:active,
button:focus,
button:hover {
  outline: 0;
}

button:active,
button:hover {
  background-color: rgba(37, 52, 247, 0.8);
}

button:disabled {
  background-color: rgba(133, 138, 156, 0.24);
  border-color: rgba(133, 138, 156, 0.24);
  pointer-events: none;
}

button.round {
  border-radius: 50px;
}

button.small {
  font-size: 10px;
  line-height: 10px;
  padding: 8px 9px;
}

button.outline {
  background: 0 0;
  color: #2534f7;
  border: 1px solid #2534f7;
}

button.outline:focus,
button.outline:hover {
  background-color: rgba(37, 52, 247, 0.12);
}

button.outline:active {
  background-color: rgba(37, 52, 247, 0.08);
}

button.outline:disabled {
  background-color: transparent;
  border-color: rgba(133, 138, 156, 0.24);
  pointer-events: none;
}

button.text {
  background: 0 0;
  color: #2534f7;
  border: 1px solid transparent;
}

button.text:focus,
button.text:hover {
  background-color: rgba(37, 52, 247, 0.12);
}

button.text:active {
  background-color: rgba(37, 52, 247, 0.08);
}

button.text:disabled {
  background-color: transparent;
  border-color: transparent;
  pointer-events: none;
}
