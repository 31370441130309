body {
  background-color: #f8f9fc;
  color: #171b2c;
  font-family: Inter, sans-serif;
}

.card-container {
  background-color: #f8f9fc;
}
.card-container {
  z-index: 5;
  position: relative;
}

.card-container,
.cards-container {
  vantage-platform-card-row {
    &.space-between {
      justify-content: space-between;
    }

    &.no-v_divider .vp-col {
      border-right: none !important;
    }
  }

  .vp-col {
    display: flex;
    width: 100%;
    box-shadow: none !important;

    &:not(:last-child) {
      @media (min-width: $sm) {
        border-right: 1px solid $card_border_color;
      }
    }

    @media (min-width: $sm) {
      width: auto;
    }
  }

  .vp-col:first-of-type {
    v4-tile-text .tile-text {
      padding-left: 0;
    }
  }

  .vp-col:last-of-type .vp-col {
    v4-tile-text .tile-text {
      padding-bottom: 36px;
    }
  }
}

.search-container {
  z-index: 6;
} // 5

section.page.headerVisible {
  .map-container {
    width: calc(100vw - 88px);
    margin-top: 80px;
  }
  @include inverse-breakpoint('68em') {
    margin-top: 56px;
    margin-left: 0;
    height: calc(100vh - 56px);
    width: 100vw;
    .map-container {
      margin-top: 80px;
      width: 100vw;
    }
    //margin-left:0px;
    .search-container {
      width: 100vw;
    }
  }
  	@include inverse-breakpoint('42.5em') {
    	margin-top:56px;
  		height: calc(100vh - 56px);
  	    .map-container {
  	    	margin-top:56px;
       		width: 100vw;
  	    }
  	}
}
body {
  section.page.headerVisible {
    @include inverse-breakpoint('68em') {
      margin-top: 80px;
      height: calc(100vh - 80px);
    }
    @include inverse-breakpoint('42.5em') {
      margin-top:56px;
			height: calc(100vh - 56px);
    }
  }
}

vp-side-nav.open + section.page {
  .map-container {
    width: calc(100vw - 320px);
  }
}

.mobile-only {
  display: none;
  @include inverse-breakpoint('68em') {
    display: block;
  }
}
.desktop-only {
  @include inverse-breakpoint('68em') {
    display: none;
  }
}

.main-container {
  height: 100%;
  position: relative;

  .ps__rail-y {
    z-index: 13;
  }

  /*@include inverse-breakpoint('68em') {

		.mobile-search, .card-container {
			display:none;
		}
		&.info-view {
			.map-placeholder, .map-container, .search-container.mobile-only, .info {
				display:none;
			}

			.card-container {
				display:block;
			}
		}
	}*/
  @include inverse-breakpoint('68em') {

    .card-container {
      z-index: 11;
      @include transitionfixed;
      -moz-transform: translateX(101vw);
      -webkit-transform: translateX(101vw);
      -o-transform: translateX(101vw);
      -ms-transform: translateX(101vw);
      transform: translateX(101vw);
      min-height: 300px;

      & > .empty-container > vantage-platform-empty{
        min-height: 300px;
      }
    }

    .map-container {
      opacity: 1;
      -webkit-transition: opacity 400ms ease-in-out;
      -moz-transition: opacity 400ms ease-in-out;
      -ms-transition: opacity 400ms ease-in-out;
      -o-transition: opacity 400ms ease-in-out;
      transition: opacity 400ms ease-in-out;
    }

    &.info-view {
      .map-container {
        opacity: 0;
      }
      .map-placeholder,
      .map-container,
      .info {
        //display:none;
        z-index: 1;
      }
      .search-container.mobile-only {
        z-index: 12;
      }

      .card-container {
        -moz-transform: translateX(0vw);
        -webkit-transform: translateX(0vw);
        -o-transform: translateX(0vw);
        -ms-transform: translateX(0vw);
        transform: translateX(0vw);
      }
    }
  }
  &.searching {
    .map-placeholder {
      height: 0;
      max-height: 0;
    }
  }
}
.right {
  float: right;
}
.ghost {
  background-color: transparent;
  color: $blue;
  border: 1px solid $blue;
  &:hover {
    background-color: $lightblue;
  }
}
.link {
  display: inline;
  text-decoration: underline;
  border: 0 solid transparent;
  background-color: transparent;
  text-transform: none;
  padding: 0;
  font-size: inherit;
  &:hover {
    background-color: transparent;
  }
}
.empty-state {
  text-align: center;
  padding-top: 20px;
  h4 {
    font-size: 18px;
    margin-bottom: 0;
  }
  p {
    margin-top: 10px;
  }
}
.inactive {
  opacity: 0.2 !important;
  pointer-events: none;
}

.has-tooltip {
  .icon-info {
    opacity: 0;
    pointer-events: none;
    @include inverse-breakpoint('40em') {
      opacity: 1;
    }
  }
  &:hover {
    .icon-info {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.ps__rail-y {
  z-index: 13;
}

//
