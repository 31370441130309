/*Custom Styles*/
@import 'variables'; // global variables first so they're accessible to rest of stylesheets
@import 'mixins'; // global mixins next to use variables and be accessible to rest of stylesheets
@import 'global'; // global style rules are defined last so they can use all the previous sheets
@import 'map'; // map styles
@import 'search'; // search styles
@import 'card'; // card styles
@import 'buttons'; // buttons and icons styles
@import 'inputs'; // input styles
@import 'modals'; // modals
@import 'pagination'; // pagination

@import 'covid'; // for covid specific project, not final production

vp-apps-suite {
  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {
    width: 60%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}
