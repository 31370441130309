@import 'variables';
@import 'mixins';

.product-item {
  @include v4_text_subtitle_2();
  transition: 0.5s;
  margin-left: 40px;
  display: flex;
  align-items: center;

  .icon {
    padding: 5px;
    margin-right: 8px;
    border-radius: 10px;
    background: $navigation_header_icon_bg;
    .path1:before {
      color: #736dff;
    }

    &-product_focus {
      background: $navigation_header_icon_bg;
    }

    &-product_profiles {
      background: $profiles-app-icon-color;
    }

    &-product_analytics {
      background: $analytics-app-icon-color;
    }

    &-product_work {
      background: $work-app-icon-color;
    }

    &-product_reports {
      background: $reports-app-icon-color;
    }

    &-product_submission {
      background: $submission-app-icon-color;
    }
  }

  .title {
    transition: 0.5s;
    color: $black_pearl;
  }
}

.question {
  font: {
    size: 12px;
    weight: 400;
  }
  margin: 18px 0;
  line-height: 18px;
  color: $card_header_color;
  display: inline-flex;

  @media (min-width: $sm) {
    font-size: 14px;
  }

  &::first-letter {
    text-transform: uppercase;
  }
}
