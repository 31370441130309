$font-inter: 'Inter', sans-serif;

@mixin v4_text_headline_1 {
  font-family: $font-inter;
  font-size: 48px;
  font-weight: 700;
  font-style: normal;
  line-height: 57px;
  /* identical to box height */
  letter-spacing: 0.002em;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

@mixin v4_text_headline_2 {
  font-family: $font-inter !important;
  font-weight: 600;
  font-style: normal;
  font-size: 46px;
  line-height: 55px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  letter-spacing: 0%;
}

@mixin v4_text_headline_3 {
  font-family: $font-inter;
  font-size: 40px;
  font-weight: 500;
  font-style: normal;
  line-height: 48px;
  /* identical to box height */
  font-feature-settings: 'tnum' on, 'lnum' on;
  letter-spacing: 0%;
}

@mixin v4_text_headline_4 {
  font-family: $font-inter !important;
  font-size: 30px;
  font-weight: 700;
  font-style: normal;
  line-height: 36px;
  /* identical to box height */
  letter-spacing: 0.003em;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

@mixin v4_text_headline_5 {
  font-family: $font-inter;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  line-height: 26px;
  /* identical to box height */
  font-feature-settings: 'tnum' on, 'lnum' on;
  letter-spacing: 0%;
}

@mixin v4_text_headline_6 {
  font-family: $font-inter;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.001em;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

@mixin v4_text_subtitle_1 {
  font-family: $font-inter;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  line-height: 22px;
  /* identical to box height */
  font-feature-settings: 'tnum' on, 'lnum' on;
  letter-spacing: 0%;
}

@mixin v4_text_subtitle_2 {
  font-family: $font-inter;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 19px;
  /* identical to box height */
  font-feature-settings: 'tnum' on, 'lnum' on;
  letter-spacing: 0%;
}

@mixin v4_text_subtitle_3 {
  font-family: $font-inter;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  line-height: 19px;
  /* identical to box height */
  font-feature-settings: 'tnum' on, 'lnum' on;
  letter-spacing: 0%;
}

@mixin v4_text_body_1 {
  font-family: $font-inter;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  /* identical to box height, or 143% */
  font-feature-settings: 'tnum' on, 'lnum' on;
}

@mixin v4_text_body_2 {
  font-family: $font-inter;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 100%;
  /* identical to box height, or 143% */
  font-feature-settings: 'tnum' on, 'lnum' on;
}

@mixin v4_text_caption {
  font-family: $font-inter;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: 100%;
  /* identical to box height, or 143% */
  font-feature-settings: 'tnum' on, 'lnum' on;
}

@mixin v4_text_overline {
  font-family: $font-inter;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  line-height: 12px;
  text-transform: uppercase;
  /* identical to box height, or 143% */
  font-feature-settings: 'tnum' on, 'lnum' on;
}

@mixin v4_text_button {
  font-family: $font-inter;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 14px;
  text-transform: uppercase;
  /* identical to box height, or 143% */
  font-feature-settings: 'tnum' on, 'lnum' on;
}

// SHADOWS

@mixin shadow_inner_bottom {
  box-shadow: inset 0px -1px 0px rgba($black_pearl_1, 0.1);
}

@mixin shadow_inner_top {
  box-shadow: inset 0px 1px 0px rgba($black_pearl_1, 0.1);
}

@mixin shadow_inner_right {
  box-shadow: inset -1px 0px 0px rgba($black_pearl_1, 0.1);
}

@mixin shadow_inner_left {
  box-shadow: inset 1px 0px 0px rgba($black_pearl_1, 0.1);
}

@mixin shadow_inner(
  $x: 0px,
  $y: 0px,
  $blur: 1px,
  $spread: 1px,
  $color: rgba($black_pearl_1, 0.1)
) {
  box-shadow: inset $x $y $blur $spread $color;
}

@mixin shadow_bottom(
  $x: 0px,
  $y: 1px,
  $blur: 0px,
  $spread: 0px,
  $color: rgba($black_pearl_1, 0.1)
) {
  box-shadow: $x $y $blur $spread $color;
}

@mixin shadow_top(
  $x: 0px,
  $y: -1px,
  $blur: 0px,
  $spread: 0px,
  $color: rgba($black_pearl_1, 0.1)
) {
  box-shadow: $x $y $blur $spread $color;
}

@mixin shadow_right(
  $x: 1px,
  $y: 0px,
  $blur: 0px,
  $spread: 0px,
  $color: rgba($black_pearl_1, 0.1)
) {
  box-shadow: $x $y $blur $spread $color;
}

@mixin shadow_left(
  $x: -1px,
  $y: 0px,
  $blur: 0px,
  $spread: 0px,
  $color: rgba($black_pearl_1, 0.1)
) {
  box-shadow: $x $y $blur $spread $color;
}

@mixin shadow(
  $x: 0px,
  $y: 0px,
  $blur: 1px,
  $spread: 0px,
  $color: rgba($black_pearl_1, 0.1)
) {
  box-shadow: $x $y $blur $spread $color;
}

@mixin sizeHW($wight, $height) {
  height: $height;
  width: $wight;
}

@mixin fade-animation-color {
  white-space: nowrap;
  color: rgba($navigation_header_text_color, 0);
  transition: all 500ms linear;
}

@mixin portables() {
  @include media-breakpoint-down(md) {
    // portable devices
    @media (pointer: coarse) {
      @content;
    }
  }
}
