.covid {
  .searching {
    .map-placeholder {
      // max-height: calc(100vh - 310px);
    }
  }
  .map-container,
  .map-placeholder {
    height: 100%;
    // height:100vh;
    // max-height: calc(100vh - 110px);
    .mapboxgl-map {
      height: 100%;
    }
  }
  .info {
    top: 80px;
    right: 84px;
  }
  @include inverse-breakpoint('68em') {
    .map-container,
    .map-placeholder {
      max-height: calc(100vh - 60px);
    }
    .info {
      top: auto;
      right: auto;
    }
  }
}
