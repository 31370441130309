.dropdown-container {
	position:relative;
	@include transitionfixed;
	.dropdown {
		display:block;
		position:absolute;
		top:100%;
		background-color:$white;
		max-height:0;
		@include transitionfixed;
		overflow:hidden;
		border-radius: 4px;
		opacity:0;
	}
	&.info-btn {
			.dd-inner {
				padding:24px;
				h4 {
					margin-top:0;
				}
			}
		.dropdown {
			width:530px;
			max-width:80vw;
			right: 0;
		}
	}
}
.dropdown-checkbox {
	position:absolute;
	left:-5000px;
	&:checked ~ .dropdown-container {

		background-color:$greyicon;
		&:before {
			border:2px solid $greytext;
			border-bottom:0 solid transparent;
			@include transitionfixed;
		}
		&:after {
			border:2px solid $greytext;
			border-bottom:0px solid transparent;
			border-left:0px solid transparent;
		}
		.dropdown {
			opacity:1;
			max-height:500px;
			box-shadow: 0px 8px 20px $shadowBlack;
			z-index:2;
		}
		p {
			font-size: 14px;
			line-height: 20px;
		}
		&.info-btn {
			.icon-info {
				border-color:$greytext;
				&:before, &:after {
					background-color:$greytext;
				}
			}
			.dropdown {
				overflow-y: scroll;
				right: 0;
				width:530px;
				max-width:80vw;
				z-index:2;
			}
		}
	}
}
.radio-group-section {
	input {
		position:absolute;
		left:-1000px;
		&:checked ~ label {
			&:before {
				border-color:$blue;
			}
			&:after {
				background-color:$blue;
			}
		}
	}
	label {
		padding-left:36px;
		position:relative;
		&:before {
			position:absolute;
			left:0px;
			top:-1px;
			display:inline-block;
			content:"";
			margin-right:10px;
			border-radius:100%;
			border:2px solid $border-color;
			width:16px;
			height:16px;
		}
		&:after {
			@include transitionfixed;
			position:absolute;
			background-color:transparent;
			left:5px;
			top:4px;
			display:inline-block;
			content:"";
			margin-right:10px;
			border-radius:100%;
			width:10px;
			height:10px;
		}
	}
	.label-section {
		padding:22px 22px;
		display:block;
		border-bottom:1px solid $border-color;
	}
}
.check-toggle {
	display:inline-block;
	position:relative;
	height:20px;
	width:36px;
	&:before {
		@include transitionfixed;
		content:"";
		display:block;
		position:absolute;
		width:34px;
		height:14px;
		border-radius:7px;
		top:3px;
		left:1px;
		background-color:$transgreyborder;
	}
	&:after {
		@include transitionfixed;
		content:"";
		display:block;
		position:absolute;
		width:20px;
		height:20px;
		left:0;
		top:0;
		border-radius:100%;
		background-color:$white;
		box-shadow: 0px 1px 3px $dualShadowBlack1, 0px 2px 2px $dualShadowBlack2, 0px 0px 2px $dualShadowBlack3;
	}
}

.toggle-check {
	position:absolute;
	left:-10000px;
	&:checked ~ .check-toggle {
		&:before {
			background-color:$blue;
		}
		&:after {
			left:16px;
		}
	}
}
