[class*=' icon-svg'],[class^=icon-svg]  {
	padding:5px;
	&:before {
		display:inline-block;
		width:24px;
		height:24px;
		content:'';
		opacity:0.6;
    background-size: 24px;
	}

}
.nav-item.active {
	[class*=' icon-svg'],[class^=icon-svg]  {
		&:before {
			opacity:1;
		}
	}
}
.icon-svg-population {
	&:before {
		background-image:url('/assets/images/font-icons/wc.svg');
	}
}
.icon-svg-socio-economic {
	&:before {
		background-image:url('/assets/images/font-icons/socio-economic.svg');
	}
}
.icon-svg-local_hospital {
	&:before {
		background-image:url('/assets/images/font-icons/local_hospital.svg');
	}
}
.icon-svg-local_hotel {
	&:before {
		background-image:url('/assets/images/font-icons/local_hotel.svg');
	}
}
.icon-svg-communicable-disease {
	&:before {
		background-image:url('/assets/images/font-icons/communicable-disease.svg');
	}
}
.icon-svg-disease {
	&:before {
		background-image:url('/assets/images/font-icons/disease.svg');
	}
}
.icon-svg-pregnant_woman {
	&:before {
		background-image:url('/assets/images/font-icons/pregnant_woman.svg');
	}
}
// icomoon couldn't generate font icon properly; need to use icon directly
.icon-svg-piece_of_paper {
  &:before {
    background-image: url(/assets/images/font-icons/piece_of_paper.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.8;
  }
}
