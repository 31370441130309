button {
	@include transitionfixed;
}
.icon-info {
	width:18px;
	height:18px;
	display:block;
	position:relative;
	border-radius:100%;
	border:2px solid $greyicon;
	@include transitionfixed;
	&:before, &:after {
		content:"";
		display:block;
		background-color:$greyicon;
		height:2px;
		width:2px;
		top:4px;
		left:8px;
		position:absolute;
		@include transitionfixed;
	}
	&:after {
		height:6px;
		top:8px;
	}
}
.icon-layers {
	position:absolute;
	width: 100%;
    height: 100%;
    left: 0;
    top: 0;
	span {
		background-color:$greytext;
		position:absolute;
		width:16px;
		height:16px;
		border-radius:2px;
		top:14px;
		left:18px;
		@include transitionfixed;
		&:before, &:after {
			@include transitionfixed;
			content:"";
			display:block;
			position:absolute;
			width:10px;
			height:2px;
			left:3px;
			top:7px;
			background-color:$white;
		}
		&:after {
			width:2px;
			height:10px;
			left:7px;
			top:3px;
		}
	}
	&:after {
		@include transitionfixed;
		display:block;
		content:"";
		position: absolute;
		width:14px;
		height:14px;
		border-bottom:2px solid $greytext;
		border-left:2px solid $greytext;
		left:14px;
		top:18px;
		border-radius:2px;
	}
}
.icon-globe-blue  {
	width:20px;
	height:20px;
	display:inline-block;
	background-repeat:no-repeat;
	background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 20 20' style='enable-background:new 0 0 20 20;' xml:space='preserve'><defs></defs><path style='fill-rule:evenodd;clip-rule:evenodd;fill:rgb(37,52,247)' d='M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10 S15.5,0,10,0z M9,17.9c-3.9-0.5-7-3.9-7-7.9c0-0.6,0.1-1.2,0.2-1.8L7,13v1c0,1.1,0.9,2,2,2V17.9z M14,14c0.9,0,1.6,0.6,1.9,1.4 C17.2,14,18,12.1,18,10c0-3.4-2.1-6.2-5-7.4V3c0,1.1-0.9,2-2,2H9v2c0,0.6-0.4,1-1,1H6v2h6c0.6,0,1,0.4,1,1v3H14z'/></svg>");
}
.icon-globe {
	width:20px;
	height:20px;
	display:inline-block;
	background-repeat:no-repeat;
	background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 20 20' style='enable-background:new 0 0 20 20;' xml:space='preserve'><defs></defs><path style='fill-rule:evenodd;clip-rule:evenodd;fill:rgb(133,138,156)' d='M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10 S15.5,0,10,0z M9,17.9c-3.9-0.5-7-3.9-7-7.9c0-0.6,0.1-1.2,0.2-1.8L7,13v1c0,1.1,0.9,2,2,2V17.9z M14,14c0.9,0,1.6,0.6,1.9,1.4 C17.2,14,18,12.1,18,10c0-3.4-2.1-6.2-5-7.4V3c0,1.1-0.9,2-2,2H9v2c0,0.6-0.4,1-1,1H6v2h6c0.6,0,1,0.4,1,1v3H14z'/></svg>");
}
.switch-view {
	width:56px;
	height:56px;
	display:none;
	background-color: $white;
	box-shadow: 0px 3px 12px $shadowBlack;
	bottom:150px;
	right:24px;
	z-index: 12;
	position:fixed;
	border-radius:100%;
	border:0 solid transparent;
	&:hover {
		background-color:$white;
	}
	span {
		display:block;
		width:11px;
		height:8px;
		position:absolute;
		top:0;
		left:0;
		left:18px;
		top:27px;
		&:before {
			display:block;
			position:absolute;
			content:"";
			top: 3px;
			width:9px;
			height:2px;
			left:0;
			background-color:$text-color;
		}
		&:after {
			display:block;
			position:absolute;
			content:"";
			right:1px;
			top:1px;
			border:3px solid transparent;
			border-bottom:3px solid $text-color;
			border-left:3px solid $text-color;
			transform: rotate(225deg);
		}
		&:first-of-type {
			transform: rotate(180deg);
			left:27px;
			top:21px;
		}
	}
	@include inverse-breakpoint('68em') {
		display:block;
	}
}
.showbtn {
	background-color:transparent;
	width:100%;
	border:0 solid transparent;
	color:$blue;
	text-align:center;
	display:block;
	span {
		display:inline;
	}
	&:hover {
		background-color:transparent;
		border:0 solid transparent;
		color:$blue;
	}
	.less {
		display:none;
	}
	.icon {
		position:relative;
		display:inline-block;
		width:13px;
		margin-right:0.5em;
		&:before, &:after {
			content:"";
			display:block;
			position:absolute;
			width:12px;
			height:2px;
			left:0;
			top:-5px;
			background-color:$blue;
		}
		&:after {
			transform: rotate(90deg);
		}
	}
	&.showless{
		.icon {
			&:after {
				display:none;
			}
		}
		.more {
			display:none;
		}
		.less {
			display:inline;
		}
	}
}
.orange-hospital-icon {
	position:relative;
	background-color:$orange;
	width:46px;
	height:46px;
	padding:12px;
	border-radius:4px;
	border:0 solid transparent;
	box-sizing:border-box;
	display: block;
	span {
		background-color:$white;
		border-radius:2px;
		position:relative;
		width:22px;
		height:22px;
		display:block;
		&:before, &:after {
			content:"";
			display:block;
			position:absolute;
			background-color:$orange;
			top:9px;
			left:4px;
			width:14px;
			height:4px;
		}
		&:after {
			left:9px;
			top:4px;
			height:14px;
			width:4px;
		}
	}
}
.bookmark {
	position:relative;
	background-color:transparent;
	border:transparent;
	width:40px;
	height:40px;
	border-radius:100%;
	padding:0;
	display:block;
	cursor:pointer;
	&:hover {
		background-color:$greyicon;
		&:before {
			border:2px solid $greytext;
			border-bottom:0 solid transparent;
			@include transitionfixed;
		}
		&:after {
			border:2px solid $greytext;
			border-bottom:0px solid transparent;
			border-left:0px solid transparent;
		}
	}
	&:before, &:after {
		@include transitionfixed;
		content:"";
		position:absolute;
		display:block;
		border:2px solid $greyicon;
	}
	&:before {
		top:11px;
		left:13px;
		border-top-right-radius:2px;
		border-top-left-radius:2px;
		border-bottom:0 solid transparent;
		width:10px;
		height:15px;
	}
	&:after {
		transform: rotate(-34deg) skewX(22.5deg);
		width:6px;
		height:6px;
		border-bottom:0px solid transparent;
		border-left:0px solid transparent;
	    left: 16px;
    	bottom: 8px;
	}
	&.marked {
		&:before {
			background-color:$blue;
			border-color:$blue;
			border-bottom:0 solid transparent;
		}
		&:after {
			background-color:$white;
			border-color:transparent;
		}
		&:hover {
			&:after {
				background-color:$greyicon;
			}
		}
	}
	.dropdown {
		z-index:2;
		min-width: 300px;
    	right: -90px;
		h4 {
			padding: 0 16px;
			padding-top:24px;
			font-size: 16px;
			line-height: 19px;
			margin: 0;
			font-weight: 500;
		}
		h5 {
			font-size: 12px;
			font-weight:normal;
			color:$greytext;
			margin-top:24px;
			padding:0 16px;
			margin-bottom:16px;
			line-height:100%;
		}
		ul {
			margin:0 auto;
			padding-left:0;
			list-style-type:none;

			button {
				border-radius:0;
				color:$text-color;
				font-size:14px;
				font-weight:normal;
				background-color:$white;
				width:100%;
				position:relative;
				padding:0 48px 0 16px;
				padding-right:75px;
				line-height:48px;
				border:0 solid transparent;
				text-transform:capitalize;
				&:before {
					content:"save";
					display:inline-block;
					position:absolute;
					right:33px;
					font-size:12px;
					color:$blue;
					opacity:0;
					@include transitionfixed;
				}
				&:hover {
					@include transitionfixed;
					background-color: $lightblue;
					&:before {
						opacity:1;
					}
				}
			}
		}
		.bottom-btn {
			border-top-left-radius:0;
			border-top-right-radius:0;
			width:100%;
			text-align:center;
			height:56px;
			line-height:56px;
			padding:0;
			display:block;
		}
	}
}
.upload {
	position:relative;
	width:40px;
	height:40px;
	background-color: transparent;
	border:0 solid transparent;
	padding:0;
	border-radius:100%;
	@include transitionfixed;
	cursor:pointer;
	&:hover {
		background-color:$greyicon;
		span {
			background-color: $greytext;
		}
		&:before {
			background-color: $greytext;
		}
		&:after {
			border-bottom:7px solid $greytext;
		}
	}
	&:before {
		@include transitionfixed;
		display:block;
		content:"";
		background-color:$greyicon;
		width:6px;
		height:7px;
		left:4px;
		bottom:4px;
		position:absolute;
		left:17px;
		bottom:16px;
	}
	&:after {
		@include transitionfixed;
		content:"";
		display:block;
		border:7px solid transparent;
		border-bottom:7px solid $greyicon;
		position:absolute;
		left:13px;
		bottom:22px;

	}
	span {
		@include transitionfixed;
		position:absolute;
		height:2px;
		width:14px;
		background-color:$greyicon;
		left:13px;
		bottom:12px;
	}
}
.forward {
	position:relative;
	width:40px;
	height:40px;
	background-color: transparent;
	border:0 solid transparent;
	border:1px solid transparent;
	padding:0;
	border-radius:100%;
	@include transitionfixed;
	cursor:pointer;
	&:before {
		@include transitionfixed;
		display:block;
		position:absolute;
		top:15px;
		left:10px;
		content:"";
		width:12px;
		height:20px;
		border:0px solid transparent;
		border-top-left-radius:15px;
		border-top: 5px solid $greyicon;
	}
	&:after {
		@include transitionfixed;
		content:"";
		display:block;
		position:absolute;
		left:20px;
		top:11px;
		width:0px;
		height:0px;
		border:7px solid transparent;
		border-left:7px solid $greyicon;
	}
	&:hover {
		background-color:$greyicon;
		&:before {
			border-top: 5px solid $greytext;
		}
		&:after {
			border-left:7px solid $greytext;
		}
	}
}
.share {
	position:relative;
	width:40px;
	height:40px;
	background-color: transparent;
	border:0 solid transparent;
	border:1px solid transparent;
	padding:0;
	border-radius:100%;
	@include transitionfixed;
	cursor:pointer;
	span {
		display:block;
		position:absolute;
		border-radius:100%;
		width:6px;
		height:6px;
		background-color:$greyicon;
		left:21px;
		top:10px;
		&:first-of-type {
			left:9px;
			top:17px;
		}
		&:last-of-type {
			top:24px;
		}
	}
	&:before, &:after {
		position:absolute;
		width:16px;
		height:2px;
		content:"";
		display:block;
		left:9px;
		top:16px;
		background-color:$greyicon;
		transform: rotate(-30deg);
	}
	&:after {
		top:22px;
		transform: rotate(30deg);
	}
}
.info-btn {
	border:0 solid transparent;
	padding:0;
	width:40px;
	height:40px;
	border-radius: 100%;
	background-color:transparent;
	@include transitionfixed;
	padding:9px;
	cursor:pointer;
	&:hover {
		background-color:$greyicon;
		.icon-info {
			border-color:$greytext;
			&:before, &:after {
				background-color:$greytext;
			}
		}
	}
}
.togglebtn {
	.toggleShow {
		display:inline;
	}
	.toggleHide {
		display:none;
	}
	&.toggled {
		.toggleShow {
			display:none;
		}
		.toggleHide {
			display:inline;
		}
	}
}
.closebtn {
	position:relative;
	width:14px;
	height:14px;
	padding:0;
	border:0 solid transparent;
	background-color:transparent;
	&:before, &:after {
		content:"";
		display:block;
		position:absolute;
		width:2px;
		height:16px;
		background-color:$disabledgrey;
		top:-2px;
		left:5px;
		transform: rotate(45deg);
	}
	&:after {
		transform: rotate(-45deg);
	}
	&:hover {
		background-color:transparent;
	}
}
.button-container-right {
	padding:22px 22px;
	display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-content: stretch;
    align-items: flex-start;
    button {
    	margin-left:16px;
    	&:first-of-type {
    		margin-left:0;
    	}
    }
}
.clearbtn {
	background-color:transparent;
	color:$blue;
	border:0 solid transparent;
	&:hover {
		background-color:$white;
	}
}

