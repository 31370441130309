.chip-container {
  line-height: 30px;
  display: inline-block;
  position: relative;
  margin-right: 14px;
  margin-bottom: 12px;
  @include transitionfixed;
  transform: translate(0, 0);
  cursor: grab;

  &:active, .grabbing {
    cursor: grabbing;
  }

  .chip {
    white-space: nowrap;
    height: 32px;
    border-radius: 16px;
    padding: 0 32px 0 12px;
    color: $greytext;
    background-color: $white;
    border: 1px solid $transgreyborder;
    @include transitionfixed;
  }

  label {
    font-size: 14px;
    pointer-events: none;
  }

  .close {
    position: absolute;
    display: block;
    border-radius: 100%;
    width: 14px;
    height: 14px;
    top: 10px;
    right: 9px;
    padding: 0;
    background-color: $greybuttonbg;
    border: 0 solid transparent;
    @include transitionfixed;

    &:before, &:after {
      @include transitionfixed;
      position: absolute;
      background-color: $white;
      display: block;
      content: "";
      height: 1px;
      width: 8px;
      top: 0.408rem;
      left: 3px;
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &:hover {
    .chip {
      background-color: $greybuttonbg;
      color: $white;
      -webkit-box-shadow: 0 8px 10px 0 $transgreyborder;
      box-shadow: 0 8px 10px 0 $transgreyborder;

      .close {
        background-color: $white;

        &:before, &:after {
          background-color: $greybuttonbg;
        }
      }
    }
  }

  &:nth-of-type(1) {
    .chip {
      color: $blue;
      background-color: $tintblue;
      border: 1px solid transparent;
      //padding-right:12px;
      .close {
        background-color: $blue;

        &:before, &:after {
          background-color: $tintblue;
        }
      }

      &:hover {
        color: $tintblue;
        background-color: $blue;

        .close {
          background-color: $tintblue;

          &:before, &:after {
            background-color: $blue;
          }
        }
      }
    }
  }

  &:nth-of-type(2) {
    .chip {
      color: $yellow;
      background-color: $tintyellow;
      border: 1px solid transparent;

      .close {
        background-color: $yellow;

        &:before, &:after {
          background-color: $tintyellow;
        }
      }

      &:hover {
        color: $tintyellow;
        background-color: $yellow;

        .close {
          background-color: $tintyellow;

          &:before, &:after {
            background-color: $yellow;
          }
        }
      }
    }
  }

  &:only-of-type {
    .chip {
      padding-right: 12px;
    }
  }

  &:last-of-type {
    .informational-chip {
      color: #858A9C;
      background-color: transparent;
      border: 1px solid rgba(133, 138, 156, 0.24);
      padding: 0 12px 0 12px;
    }
  }
}

.search-container {
  padding: 24px 0;
  background-color: $white;
  position: relative;
  border-bottom: 1px solid $greyborder;
  border-top: 1px solid $greyborder;
  position: sticky;
  top: 54px;

  .searchlabel {
    position: absolute;
    background-color: $white;
    top: 0px;
    font-size: 14px;
    color: $greytext;
    top: 16px;
    left: 40px;
    padding: 0px 5px;
    font-size: 12px;
    @include transitionfixed;
  }

  .search-block {
    border: 1px solid $greyborder;
    border-radius: 4px;
    margin: 0 24px;
    padding: 12px 0px 0px 12px;
    @include transitionfixed;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;
  }

  .selected-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    order: 0;
    flex: 1 1 auto;
    align-self: stretch;
  }

  .search-input {
    border: 0px solid transparent;
    font-size: 16px;
    color: $greytext;
    display: inline-block;
    line-height: 32px;
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
    outline: none;
  }

  .expand {
    background-color: transparent;
    display: flex-item;
    width: 50px;
    height: 100%;
    order: 0;
    flex: 0 1 auto;
    align-self: stretch;
    height: 100%;
    min-height: 32px;
    border: 0 solid transparent;
    position: relative;

    span {
      position: absolute;
      top: 7px;
      left: 16px;
      width: 18px;
      height: 2px;
      display: block;
      background-color: $greybuttonbg;
      @include transitionfixed;
    }

    &:before {
      position: absolute;
      top: 12px;
      left: 16px;
      width: 12px;
      height: 2px;
      display: block;
      content: "";
      background-color: $greybuttonbg;
      @include transitionfixed;
    }

    &:after {
      position: absolute;
      top: 17px;
      left: 16px;
      width: 6px;
      height: 2px;
      display: block;
      content: "";
      background-color: $greybuttonbg;
      @include transitionfixed;
    }
  }

  &.expanded {
    .search-block {
      border-color: $blue;
    }

    .searchlabel {
      color: $blue;
    }

    .expand {

    }
  }

  .megamenu-container {
    height: 0;

    .megamenu {
      display: none;
    }
  }

  @include inverse-breakpoint('68em') {
    padding-top: 0;
    padding-bottom: 0;
    bottom: 0;
    position: fixed;
    border-top: 0 solid transparent;
    box-shadow: 0px 3px 12px $shadowBlack;
    height: 64px;
    width: calc(100vw);

    .search-block {
      margin: 0;
      border: 0 solid transparent;

      .selected-options {
        max-width: calc(100% - 50px);
        overflow: hidden;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: stretch;
        overflow-x: scroll;

        .search-input {
          display: none;
        }

        .chip-container {
          white-space: nowrap;
        }
      }
    }
    .searchlabel {
      display: none;
    }

    &.expanded {
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &.desktop-only {
    position: sticky;
    top: 54px;

    .megamenu {
      overflow-y: auto;
      max-height: calc(100vh - 240px);
    }
  }

  &.mobile-only {
    top: auto;
  }
}

.has-source-tooltip.tool-tip {
  //width:92%;
  //position:relative;
  display: inline-block;

  .megamenu-tooltip-container {
    position: absolute;
    right: 20px;
  }

}

.megamenu-tooltip-container {
  display: inline-block;

  .icon-info {
    width: 14px;
    height: 14px;
    border: 0px solid transparent;
    background-color: $greyicon;

    &:before {
      top: 3px;
    }

    &:after {
      top: 6px;
      height: 5px;
    }

    &:before, &:after {
      background-color: $white;
      left: 6px;
    }
  }
}

.megamenu-tooltip-box {
  position: absolute;
  left: 0px;
  z-index: 99;
  background-color: $text-color;
  color: $white;
  padding: 24px;
  border-radius: 8px;
  display: none;
  margin-top: 4px;

  h3 {
    margin-top: 0;
    font-size: 18px;
    margin-bottom: 16px;
    font-weight: 600;
  }

  p, a {
    margin-bottom: 8px;
    margin-top: 0;
    font-size: 14px;
    color: $white;
  }

  a {
    text-decoration: underline;
  }

  &.visible {
    display: block;

    animation: fadeInRight ease 0.1s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode: forwards; /*when the spec is finished*/

    @include inverse-breakpoint('60em') {
      animation: fadeInRight ease 0.1s;
      transform: translate(-75%, 0);
    }
  }
}

.vp-col {
  &:first-of-type {
    .megamenu-tooltip-box {
      &.visible {
        animation: fadeInRight ease 0.1s;
        @include inverse-breakpoint('60em') {
          animation: fadeIn ease 0.1s;
        }

      }
    }
  }

  &:last-of-type {
    .megamenu-tooltip-box {
      &.visible {
        animation: fadeInLeft ease 0.1s;
        transform: translate(-100%, 0);
        @include inverse-breakpoint('60em') {
          animation: fadeIn ease 0.1s;
          transform: translate(-75%, 0);
        }
      }
    }
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(-50%, -25px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0px);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate(-100%, -25px);
  }
  100% {
    opacity: 1;
    transform: translate(-100%, 0px);
  }
}


@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate(0%, -25px);
  }
  100% {
    opacity: 1;
    transform: translate(0%, 0px);
  }
}

.megamenu-container {
  -webkit-box-shadow: 0 8px 10px 0 $transgreyborder;
  box-shadow: 0 8px 10px 0 $transgreyborder;
  color: $text-color;
  position: relative;

  .megamenu {
    box-sizing: border-box;
    position: absolute;
    top: 25px;
    background-color: $white;
    padding: 40px;
    padding-bottom: 150px;
    display: grid;
    width: 100%;
    border-bottom: 1px solid $greyborder;
    -moz-transform: translateY(24 pxp);
    -webkit-transform: translateY(24 pxp);
    -o-transform: translateY(24 pxp);
    -ms-transform: translateY(24 pxp);
    transform: translateY(24 pxp);

    @include inverse-breakpoint('60em') {
      top: 0;
    }

    .grid-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @include inverse-breakpoint('60em') {
        grid-template-columns: 1fr;
      }
    }
  }

  .column {
  }

  .classification {
  }

  .class-header, .category-header {
    margin-bottom: 16px;
    font-size: 18px;
    margin-top: 0;
  }

  ul {
    padding-left: 0;
    list-style: none;
    margin-top: 0;
    margin-bottom: 32px;
  }

  li {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    padding-right: 30px;
    @include transitionfixed;

    &:hover {
      text-decoration: underline;
      color: $blue;
      cursor: pointer;
    }
  }

  .search-highlight {
    color: $blue;
    //font-weight:bold;
  }

  .unavailable {
    color: $disabledgrey;
    pointer-events: none;

    .search-highlight {
      color: $disabledgrey;
    }
  }

  /*@include inverse-breakpoint('68em') {
    height:0;
    .megamenu {}
  }*/


  .category-container {
    .category {
    }

    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;

      li {
        .tool-tip-canary, .megamenu-tooltip-box {
          max-width: 40vw;
        }

        .icon-info {
          opacity: 0;
          pointer-events: none;

          -moz-transform: translateY(-2px);
          -webkit-transform: translateY(-2px);
          -o-transform: translateY(-2px);
          -ms-transform: translateY(-2px);
          transform: translateY(-2px);
        }

        &:hover {
          .icon-info {
            opacity: 1;
            pointer-events: auto;
          }
        }

        .visibleChild {
          .icon-info {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }

      @include breakpoint('80em') {
        grid-template-columns: 1fr 1fr 1fr;
        li {
          .tool-tip-canary, .megamenu-tooltip-box {
            max-width: 22vw;
          }
        }
      }
      @include inverse-breakpoint('60em') {
        grid-template-columns: 1fr;
        li {
          > div {
            width: 100%;
          }

          .tool-tip-canary {
            max-width: 75vw;
          }

          .megamenu-tooltip-container {
            position: absolute;

            &.visibleChild {
              position: relative;
              width: 100%;
              left: -40px;

              .icon-info {
                top: -20px;
                right: 15px;
                background-color: $blue;

                -moz-transform: translateY(-2px);
                -webkit-transform: translateY(-2px);
                -o-transform: translateY(-2px);
                -ms-transform: translateY(-2px);
                transform: translateY(-2px);
              }
            }
          }

          .icon-info {
            position: absolute;
            right: -15px;
            top: 5px;
            @include transitionclear;
          }

          .megamenu-tooltip-box {
            max-width: 100vw;
            min-width: 100vw;
            position: relative;
            margin-top: 4px;
            box-sizing: border-box;
            border-radius: 0;
            padding: 16px 24px;
            color: $text-color;
            background-color: $greyborder;

            h3 {
              font-size: 16px;
              margin-bottom: 10px;
            }

            p, a {
              font-size: 12px;
              color: $text-color;
            }
          }
        }
      }
    }

    li {
      position: relative;
      text-decoration: none;

      .tool-tip-canary {
        white-space: nowrap;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        transition: all .35s ease-in-out;
        max-width: 100%;
        background: linear-gradient(transparent 10%, $text-color) no-repeat -1px;

        background-position: -1px 0;
        background-size: 1px;

        text-shadow: 0 0 0 $transblack;

        &:hover {
          text-shadow: 0 0 0 white;

          background-position: -1px 100%;

          &:after {
            opacity: 1;
          }
        }

        &:before {
          position: absolute;
          white-space: normal;
          /* get the tooltip-text */
          content: attr(data-tooltip);

          /* styling */
          font-size: 10px;
          line-height: 100%;
          padding: 10px 12px;
          margin: -3.5em 0 0 -.25em;
          border-radius: 4px;
          pointer-events: none;


          /* hide the pseudo-element text */
          color: transparent;

          /* get the background of the parent element */
          background: inherit;
          background-size: 102% 90em;

          /* get the text-shadow of the parent element
           * to show the pseudo-element on :hover */
          text-shadow: inherit;
        }

        &:after {
          display: block;
          content: "";
          border: 8px solid transparent;
          border-top: 8px solid #171B2C;
          position: absolute;
          width: 0px;
          height: 0px;
          margin-left: 20px;
          margin-top: -25px;
          opacity: 0;
          transition: all .35s ease-in-out;
        }
      }

    }
  }

}


.mobile-search {
  display: none;
  position: fixed;
  z-index: 12;
  width: 100vw;
  height: 100vh;
  background-color: $white;
  top: 0;
  left: 0;
  padding-top: 64px;
  line-height: 56px;
  overflow-y: scroll;

  .search-header {
    position: fixed;
    width: 100vw;
    top: 0;
    height: 56px;
    background-color: $white;
    z-index: 1;

    input {
      outline: none;
      width: calc(100% - 65px);
      height: 56px;
      line-height: 56px;
      font-size: 14px;
      padding: 0;
      border: 0 solid transparent;
      margin-top: -1px;
    }

    input::placeholder {
      color: $midgrey;
      font-size: 14px;
    }

  }

  .back-button {
    float: left;
    width: 56px;
    height: 56px;
    border-radius: 0;
    border: 0 solid transparent;
    background-color: $white;

    &:before, &:after {
      content: "";
      position: absolute;
      display: block;
    }

    &:before {
      width: 10px;
      height: 10px;
      left: 28px;
      top: 22px;
      border-left: 2px solid $midgrey;
      border-top: 2px solid $midgrey;
      -moz-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    &:after {
      width: 12px;
      height: 2px;
      background-color: $midgrey;
      left: 29px;
      top: 27px;
    }
  }

  .selected-options {
    max-height: 1200px;
    @include transitionfixed;
    overflow: hidden;
    padding-bottom: 5px;

    &.collapsed {
      max-height: 133px;
    }

    .chip-container {
      display: block;
      padding-right: 56px;
      position: relative;
      margin-right: 0;
      margin-bottom: 0;
      padding-left: 10px;
      padding-top: 6px;
      padding-bottom: 6px;

      &:hover {
        -webkit-box-shadow: 0 1px 6px 0px $transgreyborder;
        box-shadow: 0 1px 6px 0px $transgreyborder;

        .chip {
          -webkit-box-shadow: 0 6px 10px 0 transparent;
          box-shadow: 0 6px 10px 0 transparent;
        }
      }

      .close {
        right: 68px;
        top: 16px;
      }

      .chip {
        label {
          width: 100%;
          overflow: hidden;
          display: block;
          text-overflow: ellipsis;
        }
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 19px;
        right: 20px;
        width: 16px;
        height: 6px;
        border-top: 2px solid $greyicon;
        border-bottom: 2px solid $greyicon;
      }
    }
  }

  .search-list-container {
    width: 500px;
    max-width: 100vw;
    margin: 0 auto;

    .megamenu {
      position: relative;
      position: absolute;
      padding: 16px;
      padding-bottom: 200px;

      .column {
        padding: 0 16px;
      }

      .class-header, .category-header {
        @include inverse-breakpoint('40em') {
          line-height: 20px;
        }
      }

      .grid-container {
        grid-template-columns: 1fr;
      }

      ul {
        grid-template-columns: 1fr;
      }

      li {
        padding-right: 0;
        margin-bottom: 12px;

        .icon-info {
          opacity: 1;
        }

        .megamenu-tooltip-container {
          &.visibleChild {
            left: 0;

            .icon-info {
              //right: 12px;
            }
          }
        }

        .tool-tip-canary {
          max-width: 83vw;
          overflow: hidden;
          white-space: normal;

          &:hover {
            &:after, &:before {
              display: none;
            }
          }
        }

        .megamenu-tooltip-box {
          &.visible {
            min-width: 80%;
            max-width: 560px;
            transform: translate(0, 0);
          }
        }
      }
    }
  }

  &.visible {
    @include inverse-breakpoint('68em') {
      display: block;
    }
  }

  .category-header {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }

  ul {
    border-bottom: 1px solid $transgreyborder;
  }

  .collapsible {
    max-height: 0;
    overflow: hidden;
    margin-bottom: 0;
    @include transitionfixed;
  }

  .category {
    .category-header {
      position: relative;
      padding-right: 20px;

      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 13px;
        top: 4px;
        width: 8px;
        height: 8px;
        transform: rotate(-45deg);
        border-bottom: 2px solid $midgrey;
        border-left: 2px solid $midgrey;
        @include transitionfixed;
      }
    }

    &.open {
      .collapsible {
        padding-bottom: 20px;
        margin-bottom: 0rem;
        max-height: 1000px;
      }

      .category-header {
        &:after {
          transform: rotate(135deg);
          top: 8px;
        }
      }
    }
  }
}
