// https://getbootstrap.com/docs/5.0/forms/floating-labels/

%input-highlighted {
  border-width: 2px;
  padding: 17px 15px;
  border-color: $input_focus_border_color !important;
}

.form-floating {
  position: relative;

  & > .form-control,
  & > .form-select {
    height: calc(3.5rem + 2px);
    border: 1px solid $input_border_color;
    caret-color: $input_caret_color;
    border-radius: 4px;
    padding: 18px 16px;
    padding-right: 30px;
    color: $input_color;
    box-sizing: border-box;
    box-shadow: none;
  }

  & > label {
    @include v4_text_caption();
    position: absolute;
    top: -8px;
    left: 11px;
    pointer-events: none;
    border: 1px solid transparent;
    // transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
    white-space: nowrap;
    visibility: hidden;

    background-color: $input_label_bg;
    padding: 2px 6px;

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }

  & > .form-control {
    &:focus,
    &:hover,
    &:active {
      outline: none;
    }

    &:hover {
      border-color: $input_hover_border_color;
    }

    &::-moz-placeholder,
    &::placeholder {
      color: $input_placeholder_color;
    }

    &:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
    }

    &:not(:-moz-placeholder-shown) ~ label {
      visibility: visible;
      color: $input_focus_label_color;
    }

    &:focus ~ label,
    &:not(:placeholder-shown) ~ label & ~ label,
    &:-webkit-autofill ~ label {
      visibility: visible;
      color: $input_focus_label_color;
    }

    &:focus {
      @extend %input-highlighted;
    }
    &.error {
      @extend %input-highlighted;
      border-color: $input_error_border_color !important;
    }

    &.error ~ label {
      visibility: visible;
      color: $input_error_label_color;
    }
  }

  .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
}

.assistive_text_container {
  padding: 0 18px;
}

.assistive_text {
  @include v4_text_caption();
  color: $input_error_assistive_label_color;
}
