
// Variables Use this section to set up commonly used style elements - font-family, font-size, color scheme, etc.
@import 'libs/common-ui/src/scss/_variables.scss';

$base-color: #333;

$link-color: #22ace0;
$link-hover-color: #d92562;
$link-visited-color: purple;

$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif;
$base-font-size: 100%;
$base-font-weight: 300;
$line-height: 1.5;

$heading-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$heading-font-weight: 400;

$vertical-measure: 1.5;

$border-color: #ddd;
$text-color: #171B2C;

$button-color: #22ace0;

$white: #fff;
$transgreyborder: rgba(133, 138, 156, 0.24);
$transgreybg: rgba(33, 38, 56, 0.65);
$lightgrey: #f3f3f7;
$hovergrey:#f0f0f0;
$darkgrey:#454956;
$greytext: #858A9C;
$greybuttonbg: #9699b8;
$greyborder: #DEE1EB;
$greyicon: #DADADA;
$disabledgrey: #c1c1c7;
$midgrey: #b6b9c4; //rgba(133, 138, 156, 0.6);
$ebonyclay: #1D222C;

$blue: rgb(37,52,247);
$bluehover: rgba(37,52,247,.8);
$tintblue: #eaebff;
$lightblue: #ececff;
$yellow: rgb(255,154,2);
$orange: #FA6E14;
$tintorange: #ffc267;
$tintyellow: #fff5e6;
$red: rgb(252,65,29);
$tintred: #ffece9;

$gradiated1: #3CB446;
$gradiated2: #FAC337;
$gradiated3: #FF9A02;
$gradiated4: #FA6E14;
$gradiated5: #FC411D;

// CARD
$card_shadow_color: $black_pearl_05;
$card_shadow_color_1: $black_pearl_24;
$card_border_color: #efefe5;
$card_question_border_color: #e2e2ea;
$card_question_focus_bg: $blue;
$card_question_focus_color: $white;
$card_bg: $white;
$card_offwhite_bg: #f3f3f7;

$icon-blue: #4b84ed;
$icon-purple:#821ede;
$transblack:rgba(0,0,0,0);
$shadowBlack: rgba(2, 4, 16, 0.15);
$shadowBlack2: rgba(2, 4, 16, 0.05);
$dualShadowBlack1:rgba(0, 0, 0, 0.18);
$dualShadowBlack2:rgba(0, 0, 0, 0.1);
$dualShadowBlack3:rgba(0, 0, 0, 0.12);
