@import 'styles/scss/style.scss';
$primary: $blue;
$secondary: $cool_gray;
$light: $lightgrey;
@import 'bootstrap/scss/bootstrap.scss';



@import './scss/index';

// Profiles Styles
@import 'styles/existing.scss';

body {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  background-color: #f8f9fc;
  font-family: 'Inter';
}
