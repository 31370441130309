// BREAKPOINTS
$xs: 480px;
$sm: 768px;
$md: 992px;
$lg: 1024px;

// COLORS
$alto: #dadada;

$black_pearl: #171b2c;
$black_pearl_8: rgba(#171b2c, 0.8);
$black_pearl_6: rgba(#171b2c, 0.6);
$black_pearl_54: rgba(#171b2c, 0.54);
$black_pearl_24: rgba(#171b2c, 0.24);
$black_pearl_16: rgba(#171b2c, 0.16);
$black_pearl_1: rgba(#171b2c, 0.1);
$black_pearl_05: rgba(#171b2c, 0.05);

$cool_gray: #858a9c;
$cool_gray_8: rgba(#858a9c, 0.8);
$cool_gray_6: rgba(#858a9c, 0.6);
$cool_gray_54: rgba(#858a9c, 0.54);
$cool_gray_24: rgba(#858a9c, 0.24);
$cool_gray_1: rgba(#858a9c, 0.1);

$blue: #2534f7;
$blue_8: rgba(#2534f7, 0.8);
$blue_6: rgba(#2534f7, 0.6);
$blue_54: rgba(#2534f7, 0.54);
$blue_24: rgba(#2534f7, 0.24);
$blue_12: rgba(#2534f7, 0.12);
$blue_1: rgba(#2534f7, 0.1);
$blue_08: rgba(#2534f7, 0.08);
$blue_04: rgba(#2534f7, 0.04);


$blue_ribbon: #1a4cfe;
$sunglow_yellow: #fac337;
$extasy: #fa6e14;
$purple_heart: #821ede;
$apple: #3cb446;
$pomegranate: #fc411d;
$mirage: #171b2c;
$link_water: #f6f8fd;
$mystic: #dee1eb;
$iron: #cecfd3;
$athens_grey: #f3f3f7;
$porcelain: #e9ebed;
$porcelain_5: rgba(#e9ebed, 0.24);;

$white: #fff;
$white_8: rgba(#fff, 0.8);
$white_6: rgba(#fff, 0.6);
$white_54: rgba(#fff, 0.54);
$white_24: rgba(#fff, 0.24);
$white_14: rgba(#fff, 0.14);
$white_1: rgba(#fff, 0.1);

$white_lilac: #f8f9fc;

// BUTTONS
$button_border_color: $blue;
$button_bg: $blue;
$button_contained_bg: $blue;
$button_contained_color: $white;
$button_contained_focus_bg: $blue_8;
$button_contained_active_bg: $blue_8;
$button_contained_disabled_bg: $cool_gray_24;
$button_contained_disabled_border_color: $cool_gray_24;

$button_outline_bg: transparent;
$button_outline_color: $blue;
$button_outline_border_color: $blue;
$button_outline_focus_bg: $blue_12;
$button_outline_active_bg: $blue_08;
$button_outline_disabled_bg: transparent;
$button_outline_disabled_border_color: $cool_gray_24;

$button_text_bg: transparent;
$button_text_color: $blue;
$button_text_border_color: transparent;
$button_text_focus_bg: $blue_12;
$button_text_active_bg: $blue_08;
$button_text_disabled_bg: transparent;
$button_text_disabled_border_color: transparent;

$button_close_modal_color: $cool_gray_6;

// INPUTS
$input_border_color: $cool_gray_24;
$input_caret_color: $blue;
$input_color: $black_pearl;
$input_placeholder_color: $cool_gray_54;
$input_label_bg: $white;
$input_label_color: $cool_gray_24;

$input_hover_border_color: $black_pearl;
$input_focus_border_color: $blue;
$input_focus_label_color: $blue;
$input_assistive_label_color: $cool_gray;

$input_disabled_color: $cool_gray_24;
$input_disabled_border_color: $mystic;

$input_error_label_color: $pomegranate;
$input_error_border_color: $pomegranate;
$input_error_assistive_label_color: $pomegranate;

$assistant_icon_bg: $black_pearl;
$assistant_icon_hover_bg: $black_pearl_8;
$assistant_line_bg: $cool_gray_24;
$assistant_input_color: $cool_gray_8;
$assistant_shadow_color: $black_pearl_1;
$assistant_tooltip_bg_color: $white;
$assistant_tooltip_text_color: $black_pearl;

// $input_unfilled_text_color: $black_pearl_1;
// $input_unfilled_border_color: $black_pearl_1;
// $input_filled_text_color: $black_pearl_1;
// $input_filled_border_color: $black_pearl_1;
// $input_filled_text_color: $black_pearl_1;
// $input_disabled_border_color: $black_pearl_1;
// $input_disabled_text_color: $black_pearl_1;

// NAVIGATION
$navigation_bg: $blue_ribbon;
$navigation_toggle_bg: $white;
$navigation_toggle_color: $cool_gray;
$navigation_toggle_hover_color: $white;
$navigation_toggle_hover_bg: $black_pearl;

$navigation_header_icon_color: $white;
$navigation_header_icon_bg: $blue_ribbon;
$navigation_header_text_color: $white;
$navigation_header_bg: $blue_ribbon;

$navigation_icon_color: $alto;
$navigation_text_color: $alto;
$navigation_amount_color: $white;
$navigation_chip_color: $white;
$navigation_chip_bg: $sunglow_yellow;

$navigation_hover_bg: $white_14;
$navigation_pressed_bg: $white_14;

$navigation_active_bg: $black_pearl_16;
$navigation_active_icon_color: $white;
$navigation_active_text_color: $white;
$navigation_active_amount_color: $white;
$navigation_active_chip_color: $sunglow_yellow;
$navigation_active_chip_bg: $white;

// AVATAR
$avatar_border_color: transparent;
$avatar_bg: $blue_08;
$avatar_text_color: $blue;

$badge_bg: $blue;
$badge_border_color: $white_6;

// CHIPS
$chips_outline_bg: $white_1;
$chips_outline_border_collor: $white;
$chips_outline_color: $white;

$chips_outline_focus_bg: $white_24;
$chips_outline_focus_border_collor: $white;
$chips_outline_focus_color: $white;

$chips_outline_pressed_bg: $white_54;
$chips_outline_pressed_border_collor: $white;
$chips_outline_pressed_color: $white;

$chips_outline_active_bg: $white;
$chips_outline_active_border_collor: $white;
$chips_outline_active_color: $blue;

$chips_outline_disabled_bg: transparent;
$chips_outline_disabled_border_collor: $white_24;
$chips_outline_disabled_color: $white_24;

$chips_contained_bg: $blue_1;
$chips_contained_border_collor: $blue_1;
$chips_contained_color: $blue;

$chips_contained_focus_bg: $blue_24;
$chips_contained_focus_border_collor: $blue_24;
$chips_contained_focus_color: $blue;

$chips_contained_pressed_bg: $blue_54;
$chips_contained_pressed_border_collor: $blue_54;
$chips_contained_pressed_color: $blue;

$chips_contained_active_bg: $blue;
$chips_contained_active_border_collor: $blue;
$chips_contained_active_color: $white;

$chips_contained_disabled_bg: $cool_gray_1;
$chips_contained_disabled_border_collor: $cool_gray_1;
$chips_contained_disabled_color: $cool_gray;

// HEADER
$header_bg: $link_water;

// SIDE MENU
$side_menu_header_color: $white;
$side_menu_header_bg: $blue;
$side_menu_no-collection-color: $white_24;
$side_menu_multiple_text_color: $white_8;

// DROPDOWN
$dropdown_bg: $white;
$dropdown_thumb_bg: $black_pearl_1;
$dropdown_track_shadow: $black_pearl_24;
$dropdown_shadow_24: $black_pearl_24;
$dropdown_shadow_1: $black_pearl_1;

$dropdown_item_focus_bg: $black_pearl_1;
$dropdown_item_active_bg: $black_pearl_24;
$dropdown_item_icon_bg: $black_pearl_54;

// PROFILE DROPDOWN
$profile_ddwn_bg: $dropdown_bg;
$profile_ddwn_icon_color: $black_pearl_54;
$profile_ddwn_shadow_24: $dropdown_shadow_24;
$profile_ddwn_shadow_1: $dropdown_shadow_1;
$profile_ddwn_thumb_bg: $dropdown_thumb_bg;
$profile_ddwn_track_shadow: $dropdown_track_shadow;

// CARD
$card_shadow_color: $black_pearl_05;
$card_shadow_color_1: $black_pearl_1;
$card_border_color: #efefe5;
$card_question_border_color: #e2e2ea;
$card_bottom_devider_color: $black_pearl_54;
$card_question_focus_bg: $blue;
$card_question_focus_color: $white;
$card_link_question_btn_color: $white;
$card_bg: $white;
$card_offwhite_bg: #f3f3f7;

$card_hover_bg: #f6f6f6;

$card_focus_border_color: $blue;

$card_header_bg: $white;
$card_header_color: $blue;
$card_header_indicator_bg: $blue;
$card_header_indicator_color: $white;
$card_header_btn_color: $alto;
$card_header_btn_hover_bg: $black_pearl_16;
$card_header_btn_hover_color: $cool_gray;
$card_header_btn_active_color: $sunglow_yellow;

$card_header_period_bg: $athens_grey;

$card_header_reward_bg: $blue;
$card_header_reward_color: $white;

// TILES

// KPI
$tile_kpi_frame_color: $cool_gray;

// TABLE
$table_cell_color: $black_pearl_8;
$table_header_color: $cool_gray_8;
$card_border_color: #e2e2ea;

// LOADING INDICATOR
$indicator_bg: $cool_gray_1;
$indicator_color: $blue_54;

// Create collection dropdown
$create_collections_bg: $white;
$create_collections_active_icon_color: $sunglow_yellow;
$create_collections_data_header_color: $cool_gray;
$create_collections_data_item_color: $black_pearl;
$create_collections_data_item_hover_bg: $blue_08;
$create_collections_footer_color: $blue;
$create_collections_footer_btn_color: $white;
$create_collections_footer_btn_icon_color: $white;
$create_collections_nodata_color: #858a9c;
$create_collections_nodata_icon_color: #dadada;

//Caught Up
$caught_up_color: $blue;
$caught_up_border_color: $blue;

// Share to teams form
$dropdown_item_bg_hover: $cool_gray_24;
$input_hover_bg_color: $white;
$input_placeholder_color: $cool_gray_24;
$input_placeholder_color_hover: $mirage;

// Notifications toaster
$toast-border-color: $white;
$toast-box-shadow: rgba(0, 0, 0, 0.02);
$toast-background-color: #080808;
$toast-message-color: $white;
$toast-button-color: $sunglow_yellow;

// PRODUCTS
$focus-app-icon-color: $blue;
$profiles-app-icon-color: $extasy;
$analytics-app-icon-color: $sunglow_yellow;
$reports-app-icon-color: $apple;
$matching-app-icon-color: $black_pearl;
$work-app-icon-color: $purple_heart;
$submission-app-icon-color: $black_pearl;

// APPS SUITE
$apps-suite-header-shadow-color: #efeff5;

// ON-BOARDING
$button-take-a-tour-icon-bg-color: #dadada;
$button-take-a-tour-icon-hover-color: rgba(2, 4, 16, 0.08);
$button-take-a-tour-icon-text-color: #f6f8fd;
$welcome-step-gradient-start: $blue;
$welcome-step-gradient-end: #307fef;

//GAI ASSISTANT
