@import 'mixins';

.page-header {
  display: flex;
  max-width: 1040px;
  margin: 0px auto;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: baseline;

  @include portables() {
    flex-direction: column;
  }

  button .icon {
    margin-right: 8px;
  }
}

.page-title {
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  @include v4_text_subtitle_2();

  @include portables() {
    padding-left: 11px;
    margin-bottom: 9px;
  }

  @include media-breakpoint-down(lg) {
    padding-left: 10px;
  }
}

.page-content {
  max-width: 1040px;
  margin: 0px auto;
  height: calc(100vh - 100px);

  @include media-breakpoint-down(sm) {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}
