section.page {
  height: calc(100vh - 20px);
  margin-left: 88px;
  transition: 0.5s;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
}
@media (max-width: 1199.98px) {
  section.page {
    margin-left: 0;
  }
}
section.page.headerVisible {
  margin-top: 80px;
  height: calc(100vh - 80px);
}
section.page.headerVisible v4-header {
  top: 0px;
}
section.page.headerVisible .focus-cards-wrapper,
section.page.headerVisible .collections-body-wrapper {
  height: calc(100vh - 150px);
}
section.page.headerVisible .focus-heade {
  margin-bottom: 0;
}
section.page .main-content {
  max-width: 1040px;
  margin: 50px auto;
}

@media (min-width: 992px) {
  vp-side-nav.open + section.page {
    margin-left: 320px;
  }
}

:root {
  --microtip-transition-duration: 0.1s;
  --microtip-transition-delay: 0.5s;
  --microtip-transition-easing: ease-out;
  --microtip-font-size: 14px;
  --microtip-font-weight: 400;
  --microtip-text-transform: none;
}

button {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 14px;
  text-transform: uppercase;
  /* identical to box height, or 143% */
  font-feature-settings: "tnum" on, "lnum" on;
  background: #2534f7;
  border: 1px solid #2534f7;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}
button:focus, button:hover, button:active {
  outline: none;
}

button {
  background: #2534f7;
  color: #fff;
}
button:hover {
  background-color: rgba(37, 52, 247, 0.8);
}
button:active {
  background-color: rgba(37, 52, 247, 0.8);
}
button:disabled {
  background-color: rgba(133, 138, 156, 0.24);
  border-color: rgba(133, 138, 156, 0.24);
  pointer-events: none;
}
button.round {
  border-radius: 50px;
  padding: 10px;
}
button.small {
  font-size: 10px;
  line-height: 10px;
  padding: 8px 9px;
}
button.outline {
  background: transparent;
  color: #2534f7;
  border: 1px solid #2534f7;
}
button.outline:focus, button.outline:hover {
  background-color: rgba(37, 52, 247, 0.12);
}
button.outline:active {
  background-color: rgba(37, 52, 247, 0.08);
}
button.outline:disabled {
  background-color: transparent;
  border-color: rgba(133, 138, 156, 0.24);
  pointer-events: none;
}
button.text {
  background: transparent;
  color: #2534f7;
  border: 1px solid transparent;
}
button.text:focus, button.text:hover {
  background-color: rgba(37, 52, 247, 0.12);
}
button.text:active {
  background-color: rgba(37, 52, 247, 0.08);
}
button.text:disabled {
  background-color: transparent;
  border-color: transparent;
  pointer-events: none;
}

/* ICON button */
button.icon-btn {
  border: none;
  outline: none;
  background: none;
  padding: 7px 10px;
  border-radius: 50%;
}
button.icon-btn.outline {
  border: 1px solid #dee1eb;
}
button.icon-btn .icon:before, button.icon-btn ngx-select.small.selected_outside .ngx-select .ngx-select__selected:before, ngx-select.small.selected_outside .ngx-select button.icon-btn .ngx-select__selected:before, button.icon-btn .ngx-select .ngx-select__selected .ngx-select__toggle .dropdown-toggle:before, .ngx-select .ngx-select__selected .ngx-select__toggle button.icon-btn .dropdown-toggle:before {
  font-size: 20px;
  color: #dadada;
}
button.icon-btn:active, button.icon-btn:focus, button.icon-btn:hover {
  background-color: rgba(23, 27, 44, 0.16);
  cursor: pointer;
}
button.icon-btn:active .icon:before, button.icon-btn:active ngx-select.small.selected_outside .ngx-select .ngx-select__selected:before, ngx-select.small.selected_outside .ngx-select button.icon-btn:active .ngx-select__selected:before, button.icon-btn:active .ngx-select .ngx-select__selected .ngx-select__toggle .dropdown-toggle:before, .ngx-select .ngx-select__selected .ngx-select__toggle button.icon-btn:active .dropdown-toggle:before, button.icon-btn:focus .icon:before, button.icon-btn:focus ngx-select.small.selected_outside .ngx-select .ngx-select__selected:before, ngx-select.small.selected_outside .ngx-select button.icon-btn:focus .ngx-select__selected:before, button.icon-btn:focus .ngx-select .ngx-select__selected .ngx-select__toggle .dropdown-toggle:before, .ngx-select .ngx-select__selected .ngx-select__toggle button.icon-btn:focus .dropdown-toggle:before, button.icon-btn:hover .icon:before, button.icon-btn:hover ngx-select.small.selected_outside .ngx-select .ngx-select__selected:before, ngx-select.small.selected_outside .ngx-select button.icon-btn:hover .ngx-select__selected:before, button.icon-btn:hover .ngx-select .ngx-select__selected .ngx-select__toggle .dropdown-toggle:before, .ngx-select .ngx-select__selected .ngx-select__toggle button.icon-btn:hover .dropdown-toggle:before {
  color: #858a9c;
}
button.icon-btn.active .icon:before, button.icon-btn.active ngx-select.small.selected_outside .ngx-select .ngx-select__selected:before, ngx-select.small.selected_outside .ngx-select button.icon-btn.active .ngx-select__selected:before, button.icon-btn.active .ngx-select .ngx-select__selected .ngx-select__toggle .dropdown-toggle:before, .ngx-select .ngx-select__selected .ngx-select__toggle button.icon-btn.active .dropdown-toggle:before {
  color: #fac337;
}
button.icon-btn.pressed {
  background: rgba(23, 27, 44, 0.16);
}
button.icon-btn.no-hover:hover {
  background: none;
}

button.text.round.icon-btn.profiles-btn:hover {
  background-color: #fa6e14;
}
button.text.round.icon-btn.profiles-btn:hover .icon:before, button.text.round.icon-btn.profiles-btn:hover ngx-select.small.selected_outside .ngx-select .ngx-select__selected:before, ngx-select.small.selected_outside .ngx-select button.text.round.icon-btn.profiles-btn:hover .ngx-select__selected:before, button.text.round.icon-btn.profiles-btn:hover .ngx-select .ngx-select__selected .ngx-select__toggle .dropdown-toggle:before, .ngx-select .ngx-select__selected .ngx-select__toggle button.text.round.icon-btn.profiles-btn:hover .dropdown-toggle:before {
  color: #fff;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 14px;
  margin-bottom: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.24);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: -4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #fac337;
}

input:focus + .slider {
  box-shadow: 0 0 1px #fac337;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Create the checkmark/indicator (hidden when not checked) */
}
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}
.checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}
.checkbox input:checked ~ .checkmark {
  background-color: #2196f3;
}
.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox input:checked ~ .checkmark:after {
  display: block;
}
.checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container .checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@font-face {
  font-family: "icomoon";
  /*src: url('~/assets/images/fonts/icomoon.eot');*/
  src: url("./../../assets/images/fonts/icomoon.eot");
  src: url("./../../assets/images/fonts/icomoon.eot") format("embedded-opentype"), url("./../../assets/images/fonts/icomoon.ttf") format("truetype"), url("./../../assets/images/fonts/icomoon.woff") format("woff"), url("./../../assets/images/fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon, ngx-select.small.selected_outside .ngx-select .ngx-select__selected, .ngx-select .ngx-select__selected .ngx-select__toggle .dropdown-toggle {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-delete_outline:before {
  content: "\e9db";
  color: #858a9c;
}

.icon-add:before {
  content: "\e900";
  color: #fff;
}

.icon-cancel:before {
  content: "\e915";
}

.icon-add_circle:before {
  content: "\e9d4";
  color: #fff;
}

.icon-file_download:before {
  content: "\e9d5";
}

.icon-sent:before {
  content: "\e9d6";
  color: #fff;
}

.icon-parent_node:before {
  content: "\e9d7";
  color: #fff;
}

.icon-product_matching:before {
  content: "\e9d8";
  color: #fff;
}

.icon-icon-funders:before {
  content: "\e9ca";
  color: #fff;
}

.icon-icon-manufacturer:before {
  content: "\e9cb";
  color: #fff;
}

.icon-icon-molecule:before {
  content: "\e9cc";
  color: #fff;
}

.icon-icon-countries:before {
  content: "\e9cd";
  color: #fff;
}

.icon-icon-data-lab:before {
  content: "\e9ce";
  color: #fff;
}

.icon-centralized-network:before {
  content: "\e978";
  color: #fff;
}

.icon-personal-growth:before {
  content: "\e979";
  color: #fff;
}

.icon-medical-expenses-insurance:before {
  content: "\e97a";
  color: #fff;
}

.icon-stethoscope:before {
  content: "\e97b";
  color: #fff;
}

.icon-bullet-list:before {
  content: "\e9c8";
  color: #fff;
}

.icon-account_balance_wallet:before {
  content: "\e901";
}

.icon-actions:before {
  content: "\e902";
  color: #858a9c;
}

.icon-activities:before {
  content: "\e903";
}

.icon-add_box:before {
  content: "\e904";
  color: #fff;
}

.icon-apps:before {
  content: "\e905";
}

.icon-arrow_back:before {
  content: "\e906";
  color: #6861fb;
}

.icon-arrow_back_ios:before {
  content: "\e907";
  color: #b5b9ca;
}

.icon-arrow_forward:before {
  content: "\e908";
  color: #2224fb;
}

.icon-arrow_forward_ios:before {
  content: "\e909";
  color: #b5b9ca;
}

.icon-arrow-downward:before {
  content: "\e90a";
}

.icon-arrow-upward:before {
  content: "\e90b";
}

.icon-assesment:before {
  content: "\e90c";
}

.icon-bookmark:before {
  content: "\e90d";
  color: #4b84ed;
}

.icon-bookmark_border:before {
  content: "\e90e";
}

.icon-check_box:before {
  content: "\e90f";
  color: #fff;
}

.icon-check_box_outline:before {
  content: "\e910";
  color: #fff;
}

.icon-clinic:before {
  content: "\e911";
  color: #821ede;
}

.icon-close:before {
  content: "\e912";
  color: #858a9c;
}

.icon-communicable-disease:before {
  content: "\e913";
  color: #fff;
}

.icon-connection-failed .path1:before {
  content: "\e914";
  color: rgb(248, 248, 255);
}

.icon-connection-failed .path2:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-connection-failed .path3:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-connection-failed .path4:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-connection-failed .path5:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-connection-failed .path6:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-connection-failed .path7:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-connection-failed .path8:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-connection-failed .path9:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-connection-failed .path10:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-connection-failed .path11:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-connection-failed .path12:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-connection-failed .path13:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-connection-failed .path14:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-connection-failed .path15:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-connection-failed .path16:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(23, 27, 44);
}

.icon-connection-failed .path17:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-connection-failed .path18:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(37, 39, 251);
}

.icon-connection-failed .path19:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(37, 39, 251);
}

.icon-connection-failed .path20:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(37, 39, 251);
}

.icon-connection-failed .path21:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-connection-failed .path22:before {
  content: "\e9d9";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-connection-failed .path23:before {
  content: "\e9da";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-create:before {
  content: "\e92a";
  color: #858a9c;
}

.icon-disease:before {
  content: "\e92b";
  color: #fff;
}

.icon-empty .path1:before {
  content: "\e92c";
  color: rgb(103, 91, 255);
  opacity: 0.32;
}

.icon-empty .path2:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.icon-empty .path3:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(104, 97, 251);
  opacity: 0.24;
}

.icon-empty .path4:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(104, 97, 251);
  opacity: 0.24;
}

.icon-empty .path5:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(103, 91, 255);
  opacity: 0.08;
}

.icon-empty .path6:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(103, 91, 255);
  opacity: 0.08;
}

.icon-empty .path7:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-empty .path8:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(104, 97, 251);
  opacity: 0.24;
}

.icon-empty .path9:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(103, 91, 255);
  opacity: 0.08;
}

.icon-empty .path10:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(103, 91, 255);
  opacity: 0.08;
}

.icon-empty .path11:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(104, 97, 251);
  opacity: 0.24;
}

.icon-equipment:before {
  content: "\e937";
}

.icon-expand_less:before {
  content: "\e938";
}

.icon-expand_more:before, ngx-select.small.selected_outside .ngx-select .ngx-select__selected:before, .ngx-select .ngx-select__selected .ngx-select__toggle .dropdown-toggle:before {
  content: "\e939";
}

.icon-favorite:before {
  content: "\e93a";
}

.icon-featured_playlist:before {
  content: "\e93b";
}

.icon-focus:before {
  content: "\e93c";
  color: #2534f7;
}

.icon-gesture:before {
  content: "\e93d";
  color: #fff;
}

.icon-group:before {
  content: "\e93e";
}

.icon-group-add:before {
  content: "\e93f";
  color: #fff;
}

.icon-heart:before {
  content: "\e940";
}

.icon-help:before {
  content: "\e941";
}

.icon-home:before {
  content: "\e942";
  color: #fff;
}

.icon-hospital:before {
  content: "\e943";
  color: #fa6e14;
}

.icon-how-to-reg:before {
  content: "\e944";
  color: #fff;
}

.icon-indicator:before {
  content: "\e945";
}

.icon-info_outline:before {
  content: "\e946";
  color: #858a9c;
}

.icon-interventions:before {
  content: "\e947";
}

.icon-link:before {
  content: "\e948";
  color: #858a9c;
}

.icon-local_hospital:before {
  content: "\e949";
}

.icon-local_hotel:before {
  content: "\e94a";
  color: #fff;
}

.icon-location_city:before {
  content: "\e94b";
}

.icon-logo:before {
  content: "\e94c";
  color: #fff;
}

.icon-management:before {
  content: "\e94d";
}

.icon-menu:before {
  content: "\e94e";
  color: #fff;
}

.icon-mic:before {
  content: "\e94f";
}

.icon-mobile:before {
  content: "\e950";
  color: #4b84ed;
}

.icon-move_vertical:before {
  content: "\e951";
  color: #858a9c;
}

.icon-navigation:before {
  content: "\e952";
}

.icon-no-cards .path1:before {
  content: "\e953";
  color: rgb(248, 248, 255);
}

.icon-no-cards .path2:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(246, 249, 252);
}

.icon-no-cards .path3:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-no-cards .path4:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.8;
}

.icon-no-cards .path5:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(246, 249, 252);
}

.icon-no-cards .path6:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-no-cards .path7:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-no-cards .path8:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-no-cards .path9:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}

.icon-no-cards .path10:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-no-cards .path11:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-no-cards .path12:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-no-cards .path13:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-no-cards .path14:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-no-cards .path15:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-no-cards .path16:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-no-cards .path17:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-no-cards .path18:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-no-cards .path19:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-no-cards .path20:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-no-cards .path21:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(36, 39, 251);
}

.icon-no-cards .path22:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.icon-no-cards .path23:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.icon-no-cards .path24:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.icon-no-cards .path25:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(36, 39, 251);
}

.icon-no-cards .path26:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-no-cards .path27:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.icon-no-cards .path28:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(36, 39, 251);
}

.icon-no-cards .path29:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-no-cards .path30:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-notivication:before {
  content: "\e971";
}

.icon-oversight:before {
  content: "\e972";
  color: #fff;
}

.icon-pause:before {
  content: "\e973";
  color: #fff;
}

.icon-pregnant_woman:before {
  content: "\e974";
  color: #fff;
}

.icon-product_focus:before {
  content: "\e976";
  color: #fff;
}

.icon-product_analytics:before {
  content: "\e975";
  color: #fff;
}

.icon-product_profiles:before {
  content: "\e97c";
  color: #fff;
}

.icon-product_reports:before {
  content: "\e97d";
  color: #fff;
}

.icon-product_submission:before {
  content: "\e97e";
  color: #fff;
}

.icon-product_work:before {
  content: "\e97f";
  color: #fff;
}

.icon-publicon:before {
  content: "\e980";
}

.icon-reporting:before {
  content: "\e981";
  color: #fff;
}

.icon-school:before {
  content: "\e982";
}

.icon-share:before {
  content: "\e983";
  color: #858a9c;
}

.icon-site-profiles:before {
  content: "\e984";
  color: #fff;
}

.icon-skeleton .path1:before {
  content: "\e985";
  color: rgb(255, 255, 255);
}

.icon-skeleton .path2:before {
  content: "\e986";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-skeleton .path3:before {
  content: "\e987";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-skeleton .path4:before {
  content: "\e988";
  margin-left: -1em;
  color: rgb(226, 227, 231);
}

.icon-skeleton .path5:before {
  content: "\e989";
  margin-left: -1em;
  color: rgb(226, 227, 231);
}

.icon-skeleton .path6:before {
  content: "\e98a";
  margin-left: -1em;
  color: rgb(133, 138, 156);
  opacity: 0.24;
}

.icon-skeleton .path7:before {
  content: "\e98b";
  margin-left: -1em;
  color: rgb(226, 227, 231);
}

.icon-skeleton .path8:before {
  content: "\e98c";
  margin-left: -1em;
  color: rgb(226, 227, 231);
}

.icon-skeleton .path9:before {
  content: "\e98d";
  margin-left: -1em;
  color: rgb(226, 227, 231);
}

.icon-skeleton .path10:before {
  content: "\e98e";
  margin-left: -1em;
  color: rgb(104, 97, 251);
}

.icon-skeleton .path11:before {
  content: "\e98f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-skeleton .path12:before {
  content: "\e990";
  margin-left: -1em;
  color: rgb(133, 138, 156);
  opacity: 0.24;
}

.icon-skeleton .path13:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(133, 138, 156);
  opacity: 0.24;
}

.icon-social:before {
  content: "\e992";
}

.icon-socio-economic:before {
  content: "\e993";
  color: #fff;
}

.icon-something-wrong .path1:before {
  content: "\e994";
  color: rgb(248, 248, 255);
}

.icon-something-wrong .path2:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(251, 251, 253);
}

.icon-something-wrong .path3:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-something-wrong .path4:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(242, 244, 250);
}

.icon-something-wrong .path5:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-something-wrong .path6:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}

.icon-something-wrong .path7:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}

.icon-something-wrong .path8:before {
  content: "\e99b";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}

.icon-something-wrong .path9:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(251, 251, 253);
}

.icon-something-wrong .path10:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-something-wrong .path11:before {
  content: "\e99e";
  margin-left: -1em;
  color: rgb(242, 244, 250);
}

.icon-something-wrong .path12:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-something-wrong .path13:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}

.icon-something-wrong .path14:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}

.icon-something-wrong .path15:before {
  content: "\e9a2";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}

.icon-something-wrong .path16:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(251, 251, 253);
}

.icon-something-wrong .path17:before {
  content: "\e9a4";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-something-wrong .path18:before {
  content: "\e9a5";
  margin-left: -1em;
  color: rgb(242, 244, 250);
}

.icon-something-wrong .path19:before {
  content: "\e9a6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-something-wrong .path20:before {
  content: "\e9a7";
  margin-left: -1em;
  color: rgb(246, 246, 255);
}

.icon-something-wrong .path21:before {
  content: "\e9a8";
  margin-left: -1em;
  color: rgb(246, 246, 255);
}

.icon-something-wrong .path22:before {
  content: "\e9a9";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}

.icon-something-wrong .path23:before {
  content: "\e9aa";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-something-wrong .path24:before {
  content: "\e9ab";
  margin-left: -1em;
  color: rgb(36, 39, 251);
}

.icon-something-wrong .path25:before {
  content: "\e9ac";
  margin-left: -1em;
  color: rgb(246, 246, 255);
}

.icon-something-wrong .path26:before {
  content: "\e9ad";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.3;
}

.icon-something-wrong .path27:before {
  content: "\e9ae";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.3;
}

.icon-something-wrong .path28:before {
  content: "\e9af";
  margin-left: -1em;
  color: rgb(90, 90, 121);
}

.icon-something-wrong .path29:before {
  content: "\e9b0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-something-wrong .path30:before {
  content: "\e9b1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-something-wrong .path31:before {
  content: "\e9b2";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-something-wrong .path32:before {
  content: "\e9b3";
  margin-left: -1em;
  color: rgb(246, 246, 255);
}

.icon-something-wrong .path33:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(246, 246, 255);
}

.icon-something-wrong .path34:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(246, 246, 255);
}

.icon-something-wrong .path35:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(23, 27, 44);
}

.icon-something-wrong .path36:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(75, 79, 96);
}

.icon-staff:before {
  content: "\e9b8";
  color: #fff;
}

.icon-star:before {
  content: "\e9b9";
  color: #fff;
}

.icon-stars:before {
  content: "\e9ba";
}

.icon-submit:before {
  content: "\e9bb";
  color: #fff;
}

.icon-superviced_user_circle:before {
  content: "\e9bc";
  color: #fff;
}

.icon-thumb_up:before {
  content: "\e9bd";
  color: #2534f7;
}

.icon-track_changes:before {
  content: "\e9be";
}

.icon-trophy:before {
  content: "\e9bf";
  color: #757575;
}

.icon-upload:before {
  content: "\e9c0";
}

.icon-vantage:before {
  content: "\e9c1";
  color: #757575;
}

.icon-vector:before {
  content: "\e9c2";
  color: #fff;
}

.icon-view_module:before {
  content: "\e9c3";
  color: #fff;
}

.icon-view_week:before {
  content: "\e9c4";
}

.icon-view-module:before {
  content: "\e9c5";
  color: #fff;
}

.icon-wc:before {
  content: "\e9c6";
  color: #fff;
}

.icon-web:before {
  content: "\e9c7";
  color: #fff;
}

.icon-screen_rotation:before {
  content: "\e977";
  color: #2534f7;
}

.icon-alert:before {
  content: "\e9c9";
  color: #1a4cfe;
}

.icon-show_error_log:before {
  content: "\e9cf";
}

.icon-arrow_first_ios:before {
  content: "\e9d0";
  color: #858a9c;
}

.icon-arrow_last_ios:before {
  content: "\e9d1";
  color: #858a9c;
}

.icon-pin .path1:before {
  content: "\e9d2";
  color: rgb(250, 110, 20);
  opacity: 0.1;
}

.icon-pin .path2:before {
  content: "\e9d3";
  margin-left: -1em;
  color: rgb(250, 110, 20);
}

.icon-resize-100:before {
  content: "\e9dc";
}

.icon-resize-full-screen:before {
  content: "\e9dd";
}

.icon-enlarge:before {
  content: "\e9de";
}

.icon-shrink:before {
  content: "\e9df";
}

.vantage-pattern-background {
  background-image: url("./../../assets/images/image-patterns/Vantage_Pattern_1.svg");
}

.ngx-select *,
* {
  box-sizing: border-box;
  outline: none;
}

.input-group .input-group-text {
  padding: 15px 18px;
}

.ngx-select {
  height: 56px;
  outline: 0;
}
.ngx-select .form-control:focus {
  box-shadow: none;
}
.ngx-select .ngx-select__selected .ngx-select__toggle {
  border-color: rgba(133, 138, 156, 0.24);
  height: 56px;
  padding: 16px 18px;
}
.ngx-select .ngx-select__selected .ngx-select__toggle:hover {
  background-color: #fff;
}
.ngx-select .ngx-select__selected .ngx-select__toggle:hover .ngx-select__placeholder {
  color: #171b2c;
}
.ngx-select .ngx-select__selected .ngx-select__toggle .dropdown-toggle:after {
  display: none;
}
.ngx-select .ngx-select__selected .ngx-select__toggle .ngx-select__placeholder {
  color: rgba(133, 138, 156, 0.24);
}
.ngx-select .ngx-select__search {
  height: 56px;
}
.ngx-select .ngx-select__choices {
  top: 0;
  padding: 8px 0;
  box-shadow: 0px 8px 20px rgba(2, 4, 16, 0.14);
  max-height: 239px;
  border: none;
}
.ngx-select .ngx-select__choices .ngx-select__item-group {
  padding: 0;
  background: none;
}
.ngx-select .ngx-select__choices .ngx-select__item-group .ngx-select__item {
  padding: 16px;
  line-height: 40px;
  color: #171b2c;
  background: #fff;
  border-radius: 1px;
}
.ngx-select .ngx-select__choices .ngx-select__item-group .ngx-select__item:hover {
  background-color: rgba(133, 138, 156, 0.24);
}

ngx-select.w-100 .ngx-select {
  width: 100%;
}
ngx-select.small .ngx-select {
  font-size: 12px;
  line-height: 12px;
  height: auto;
  display: inline-block;
}
ngx-select.small .ngx-select__search {
  height: 30px !important;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 5px;
  margin-left: 10px;
}
ngx-select.small .ngx-select__selected .ngx-select__toggle {
  padding: 8px;
  height: 30px;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  min-width: 130px;
}
ngx-select.small .ngx-select__selected .ngx-select__toggle .dropdown-toggle {
  font-size: 20px;
}
ngx-select.small .ngx-select__selected .ngx-select__selected-plural {
  border-radius: 16px;
  border: none;
  font-size: 12px;
  padding: 5px 10px;
  line-height: 12px;
  background: #E6D2F8;
  color: rgba(23, 27, 44, 0.8);
}
ngx-select.small .ngx-select__selected .ngx-select__selected-plural .ngx-select__clear {
  background-color: #171b2c;
  color: #fff;
  border-radius: 50%;
  padding: 3px;
  font-size: 10px;
  font-weight: 100;
}
ngx-select.small.selected_outside .ngx-select {
  padding: 0;
  position: relative;
}
ngx-select.small.selected_outside .ngx-select .ngx-select__search::placeholder {
  color: #171b2c;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
}
ngx-select.small.selected_outside .ngx-select .ngx-select__selected {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 20px !important;
}
ngx-select.small.selected_outside .ngx-select .ngx-select__selected span {
  display: none;
}
ngx-select.small.selected_outside .ngx-select .ngx-select__search {
  margin-bottom: 0;
}
ngx-select.small.selected_outside .ngx-select .ngx-select__search ::placeholder {
  color: #171b2c !important;
}
ngx-select.small .ngx-select__choices .ngx-select__item-group .ngx-select__item {
  padding: 8px;
  line-height: 18pt;
}
ngx-select.medium .ngx-select {
  height: 40px;
  display: inline-block;
}
ngx-select.medium .ngx-select__search {
  height: 40px;
}
ngx-select.medium .ngx-select__selected .ngx-select__toggle {
  padding: 12px;
  height: 40px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  min-width: 130px;
}
ngx-select.medium .ngx-select__selected .ngx-select__toggle .dropdown-toggle {
  font-size: 20px;
}
ngx-select.medium .ngx-select__choices .ngx-select__item-group .ngx-select__item {
  padding: 8px;
  line-height: 18pt;
}
ngx-select.break-spaces .ngx-select__item {
  white-space: break-spaces;
}

.modal-body {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 19px;
  /* identical to box height */
  font-feature-settings: "tnum" on, "lnum" on;
  letter-spacing: 0%;
  padding: 32px 24px;
  color: rgba(23, 27, 44, 0.6);
}

.modal-header h4 {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 19px;
  /* identical to box height */
  font-feature-settings: "tnum" on, "lnum" on;
  letter-spacing: 0%;
  margin: 0;
}
.modal-header button {
  font-size: 24px;
  color: rgba(133, 138, 156, 0.6);
  padding: 5px;
}

.modal-footer {
  justify-content: space-between;
}
.modal-footer .buttons-right-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.modal-footer .buttons-right-container button {
  margin-left: 10px;
}

.allign-footer-right .modal-footer {
  justify-content: flex-end;
}

.modal-backdrop.show {
  background: #171b2c;
  mix-blend-mode: normal;
  opacity: 0.66;
}

@media (max-width: 767.98px) {
  .modal-dialog {
    height: auto;
    min-height: 100%;
    max-width: none;
    margin: 0;
    border-radius: 0;
  }
  .modal-dialog.no-border__mobile .modal-content {
    border: none !important;
    border-radius: 0 !important;
  }
  .modal-dialog .modal-content {
    height: 100%;
  }
  .modal-dialog .modal-body {
    padding: 32px 24px;
    flex: none;
  }
  .modal-dialog .modal-footer {
    border-top: none;
    flex-direction: column;
  }
  .modal-dialog .modal-footer .buttons-right-container {
    flex-direction: column-reverse;
    padding: 22px 24px;
    width: 100%;
  }
  .modal-dialog .modal-footer button {
    margin: 0;
    margin-bottom: 10px;
    justify-content: center;
    width: 100%;
  }
}
.modal-dialog.not-supported-orientation-modal {
  max-width: 280px;
  max-height: 250px !important;
  margin: calc(50vh - 125px) auto;
  border-radius: 8px;
}
.modal-dialog.not-supported-orientation-modal .modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-radius: 8px;
}
.modal-dialog.not-supported-orientation-modal .message-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-dialog.not-supported-orientation-modal h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #171b2c;
}
.modal-dialog.not-supported-orientation-modal p {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-feature-settings: "tnum" on, "lnum" on;
  color: rgba(23, 27, 44, 0.6);
}
.modal-dialog.not-supported-orientation-modal .btn-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 576px) {
  .on-boarding-modal {
    width: 400px;
  }
}
@media (max-width: 767.98px) {
  .on-boarding-modal {
    max-width: none;
    height: 100%;
    min-height: 0;
  }
}
.on-boarding-modal .modal-content {
  border: none;
}

.vantage-on-boarding.shepherd-element {
  padding: 24px;
  border-radius: 4px;
  background-color: #171b2c;
  box-shadow: 0px -7px 18px rgba(2, 4, 16, 0.08);
  width: 100%;
  opacity: 0;
  outline: none;
  transition: opacity 0.3s, visibility 0.3s;
  visibility: hidden;
  z-index: 9999;
}
@media (min-width: 576px) {
  .vantage-on-boarding.shepherd-element {
    max-width: 400px;
  }
}
.vantage-on-boarding.shepherd-element, .vantage-on-boarding.shepherd-element:after, .vantage-on-boarding.shepherd-element:before,
.vantage-on-boarding.shepherd-element * {
  box-sizing: border-box;
}
.vantage-on-boarding.shepherd-element.shepherd-enabled {
  opacity: 1;
  visibility: visible;
}
.vantage-on-boarding.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}
.vantage-on-boarding.shepherd-element .shepherd-arrow {
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: -1;
}
.vantage-on-boarding.shepherd-element .shepherd-arrow:before {
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: -1;
  content: "";
  transform: rotate(45deg);
  background-color: #171b2c;
}
.vantage-on-boarding.shepherd-element .shepherd-content .shepherd-cancel-icon {
  width: 12px;
  height: 12px;
  color: #d1d1d5;
  background: transparent;
  border: none;
  font-size: 26px;
  cursor: pointer;
  font-weight: 400;
  margin: 0;
  padding: 0;
  transition: color 0.5s ease;
}
.vantage-on-boarding.shepherd-element .shepherd-content .shepherd-cancel-icon span {
  margin-left: -3px;
  margin-top: -4px;
}
.vantage-on-boarding.shepherd-element .shepherd-content .shepherd-header {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}
.vantage-on-boarding.shepherd-element .shepherd-content .shepherd-header .shepherd-title {
  display: flex;
  flex: 1 0 auto;
  margin: auto;
  padding: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
}
.vantage-on-boarding.shepherd-element .shepherd-content .shepherd-text {
  margin-bottom: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.8);
}
.vantage-on-boarding.shepherd-element .shepherd-content .shepherd-text p {
  margin-top: 0;
}
.vantage-on-boarding.shepherd-element .shepherd-content .shepherd-text p:last-child {
  margin-bottom: 0;
}
.vantage-on-boarding.shepherd-element .shepherd-content .shepherd-footer {
  display: flex;
  justify-content: flex-end;
}
.vantage-on-boarding.shepherd-element .shepherd-content .shepherd-footer .pagination-wrapper {
  display: flex;
  margin: auto auto 0 0;
  max-height: 6px;
}
.vantage-on-boarding.shepherd-element .shepherd-content .shepherd-footer .pagination-wrapper [class^=pagination-step] {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 6px;
  background-color: rgba(255, 255, 255, 0.24);
}
.vantage-on-boarding.shepherd-element .shepherd-content .shepherd-footer .pagination-wrapper .pagination-step-active {
  background-color: #fff;
}
.vantage-on-boarding.shepherd-element .shepherd-content .shepherd-footer .shepherd-button {
  padding-left: 25px;
  padding-right: 25px;
  margin-right: 16px;
}
.vantage-on-boarding.shepherd-element .shepherd-content .shepherd-footer .shepherd-button:last-child {
  margin-right: 0;
}

.shepherd-element[data-popper-placement^=top] > .shepherd-arrow {
  bottom: -8px;
}

.shepherd-element[data-popper-placement^=bottom] > .shepherd-arrow {
  top: -8px;
}

.shepherd-element[data-popper-placement^=left] > .shepherd-arrow {
  right: -8px;
}

.shepherd-element[data-popper-placement^=right] > .shepherd-arrow {
  left: -8px;
}

.shepherd-element.shepherd-centered > .shepherd-arrow {
  opacity: 0;
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target,
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
  pointer-events: none;
}

.shepherd-modal-overlay-container {
  -ms-filter: progid:dximagetransform.microsoft.gradient.alpha(Opacity=50);
  filter: alpha(opacity=50);
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: auto;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-out, height 0ms 0.3s, opacity 0.3s 0ms;
  width: 100vw;
  z-index: 9997;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: 0;
  transition: all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s;
}

@media (min-width: 576px) {
  .shepherd-element.vantage-on-boarding.step-insight-cards {
    margin: 0 0 -60px 100px !important;
  }
}
.shepherd-element.vantage-on-boarding.step-add-card {
  right: 15px !important;
}
.shepherd-element.vantage-on-boarding.step-share-card {
  margin-top: 15px !important;
}
@media (min-width: 576px) {
  .shepherd-element.vantage-on-boarding.step-apps {
    margin-left: 15px !important;
  }
}
.shepherd-element.vantage-on-boarding.step-assistant {
  margin-right: 15px !important;
}

button.visible {
  visibility: visible !important;
  background-color: rgba(37, 52, 247, 0.12);
}

.hover-effect-icon-btn {
  background: rgba(23, 27, 44, 0.16);
  cursor: pointer;
}
.hover-effect-icon-btn .icon:before, .hover-effect-icon-btn .ngx-select .ngx-select__selected .ngx-select__toggle .dropdown-toggle:before, .ngx-select .ngx-select__selected .ngx-select__toggle .hover-effect-icon-btn .dropdown-toggle:before, .hover-effect-icon-btn ngx-select.small.selected_outside .ngx-select .ngx-select__selected:before, ngx-select.small.selected_outside .ngx-select .hover-effect-icon-btn .ngx-select__selected:before {
  color: #858a9c;
}

.hover-effect-btn {
  background-color: rgba(255, 255, 255, 0.14);
}

[class*=" icon-svg"], [class^=icon-svg] {
  padding: 5px;
}
[class*=" icon-svg"]:before, [class^=icon-svg]:before {
  display: inline-block;
  width: 24px;
  height: 24px;
  content: "";
  opacity: 0.6;
  background-size: 24px;
}

.nav-item.active [class*=" icon-svg"]:before, .nav-item.active [class^=icon-svg]:before {
  opacity: 1;
}

.icon-svg-population:before {
  background-image: url("/assets/images/font-icons/wc.svg");
}

.icon-svg-socio-economic:before {
  background-image: url("/assets/images/font-icons/socio-economic.svg");
}

.icon-svg-local_hospital:before {
  background-image: url("/assets/images/font-icons/local_hospital.svg");
}

.icon-svg-local_hotel:before {
  background-image: url("/assets/images/font-icons/local_hotel.svg");
}

.icon-svg-communicable-disease:before {
  background-image: url("/assets/images/font-icons/communicable-disease.svg");
}

.icon-svg-disease:before {
  background-image: url("/assets/images/font-icons/disease.svg");
}

.icon-svg-pregnant_woman:before {
  background-image: url("/assets/images/font-icons/pregnant_woman.svg");
}

.icon-svg-piece_of_paper:before {
  background-image: url(/assets/images/font-icons/piece_of_paper.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.8;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.tileTooltipContainer.show {
  left: auto !important;
  right: 20px !important;
  top: 60px !important;
  opacity: 1;
}
.tileTooltipContainer.show .tooltip-arrow {
  display: none;
}
.tileTooltipContainer.show .tooltip-inner {
  max-width: none;
  background-color: transparent;
}

@media (min-width: 576px) {
  bs-tooltip-container.nowrap {
    white-space: nowrap;
  }
  bs-tooltip-container.nowrap .tooltip-inner {
    max-width: none;
  }
}

.product-item {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 19px;
  /* identical to box height */
  font-feature-settings: "tnum" on, "lnum" on;
  letter-spacing: 0%;
  transition: 0.5s;
  margin-left: 40px;
  display: flex;
  align-items: center;
}
.product-item .icon, .product-item .ngx-select .ngx-select__selected .ngx-select__toggle .dropdown-toggle, .ngx-select .ngx-select__selected .ngx-select__toggle .product-item .dropdown-toggle, .product-item ngx-select.small.selected_outside .ngx-select .ngx-select__selected, ngx-select.small.selected_outside .ngx-select .product-item .ngx-select__selected {
  padding: 5px;
  margin-right: 8px;
  border-radius: 10px;
  background: #1a4cfe;
}
.product-item .icon .path1:before, .product-item .ngx-select .ngx-select__selected .ngx-select__toggle .dropdown-toggle .path1:before, .ngx-select .ngx-select__selected .ngx-select__toggle .product-item .dropdown-toggle .path1:before, .product-item ngx-select.small.selected_outside .ngx-select .ngx-select__selected .path1:before, ngx-select.small.selected_outside .ngx-select .product-item .ngx-select__selected .path1:before {
  color: #736dff;
}
.product-item .icon-product_focus {
  background: #1a4cfe;
}
.product-item .icon-product_profiles {
  background: #fa6e14;
}
.product-item .icon-product_analytics {
  background: #fac337;
}
.product-item .icon-product_work {
  background: #821ede;
}
.product-item .icon-product_reports {
  background: #3cb446;
}
.product-item .icon-product_submission {
  background: #171b2c;
}
.product-item .title {
  transition: 0.5s;
  color: #171b2c;
}

.question {
  font-size: 12px;
  font-weight: 400;
  margin: 18px 0;
  line-height: 18px;
  color: #2534f7;
  display: inline-flex;
}
@media (min-width: 768px) {
  .question {
    font-size: 14px;
  }
}
.question::first-letter {
  text-transform: uppercase;
}

.form-floating > .form-control.error, .form-floating > .form-control:focus {
  border-width: 2px;
  padding: 17px 15px;
  border-color: #2534f7 !important;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control, .form-floating > .form-select {
  height: calc(3.5rem + 2px);
  border: 1px solid rgba(133, 138, 156, 0.24);
  caret-color: #2534f7;
  border-radius: 4px;
  padding: 18px 16px;
  padding-right: 30px;
  color: #171b2c;
  box-sizing: border-box;
  box-shadow: none;
}
.form-floating > label {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: 100%;
  /* identical to box height, or 143% */
  font-feature-settings: "tnum" on, "lnum" on;
  position: absolute;
  top: -8px;
  left: 11px;
  pointer-events: none;
  border: 1px solid transparent;
  white-space: nowrap;
  visibility: hidden;
  background-color: #fff;
  padding: 2px 6px;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control:focus, .form-floating > .form-control:hover, .form-floating > .form-control:active {
  outline: none;
}
.form-floating > .form-control:hover {
  border-color: #171b2c;
}
.form-floating > .form-control::-moz-placeholder, .form-floating > .form-control::placeholder {
  color: rgba(133, 138, 156, 0.24);
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  visibility: visible;
  color: #2534f7;
}
.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label .form-floating > .form-control ~ label, .form-floating > .form-control:-webkit-autofill ~ label {
  visibility: visible;
  color: #2534f7;
}
.form-floating > .form-control.error {
  border-color: #fc411d !important;
}
.form-floating > .form-control.error ~ label {
  visibility: visible;
  color: #fc411d;
}
.form-floating .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.assistive_text_container {
  padding: 0 18px;
}

.assistive_text {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: 100%;
  /* identical to box height, or 143% */
  font-feature-settings: "tnum" on, "lnum" on;
  color: #fc411d;
}

.page-header {
  display: flex;
  max-width: 1040px;
  margin: 0px auto;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: baseline;
}
@media (max-width: 991.98px) and (pointer: coarse) {
  .page-header {
    flex-direction: column;
  }
}
.page-header button .icon, .page-header button .ngx-select .ngx-select__selected .ngx-select__toggle .dropdown-toggle, .ngx-select .ngx-select__selected .ngx-select__toggle .page-header button .dropdown-toggle, .page-header button ngx-select.small.selected_outside .ngx-select .ngx-select__selected, ngx-select.small.selected_outside .ngx-select .page-header button .ngx-select__selected {
  margin-right: 8px;
}

.page-title {
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 19px;
  /* identical to box height */
  font-feature-settings: "tnum" on, "lnum" on;
  letter-spacing: 0%;
}
@media (max-width: 991.98px) and (pointer: coarse) {
  .page-title {
    padding-left: 11px;
    margin-bottom: 9px;
  }
}
@media (max-width: 1199.98px) {
  .page-title {
    padding-left: 10px;
  }
}

.page-content {
  max-width: 1040px;
  margin: 0px auto;
  height: calc(100vh - 100px);
}
@media (max-width: 767.98px) {
  .page-content {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}

@font-face {
  font-family: "icomoon";
  /*src: url('~/assets/images/fonts/icomoon.eot');*/
  src: url("./../../assets/images/fonts/icomoon.eot");
  src: url("./../../assets/images/fonts/icomoon.eot") format("embedded-opentype"), url("./../../assets/images/fonts/icomoon.ttf") format("truetype"), url("./../../assets/images/fonts/icomoon.woff") format("woff"), url("./../../assets/images/fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon, .ngx-select .ngx-select__selected .ngx-select__toggle .dropdown-toggle, ngx-select.small.selected_outside .ngx-select .ngx-select__selected {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-delete_outline:before {
  content: "\e9db";
  color: #858a9c;
}

.icon-add:before {
  content: "\e900";
  color: #fff;
}

.icon-cancel:before {
  content: "\e915";
}

.icon-add_circle:before {
  content: "\e9d4";
  color: #fff;
}

.icon-file_download:before {
  content: "\e9d5";
}

.icon-sent:before {
  content: "\e9d6";
  color: #fff;
}

.icon-parent_node:before {
  content: "\e9d7";
  color: #fff;
}

.icon-product_matching:before {
  content: "\e9d8";
  color: #fff;
}

.icon-icon-funders:before {
  content: "\e9ca";
  color: #fff;
}

.icon-icon-manufacturer:before {
  content: "\e9cb";
  color: #fff;
}

.icon-icon-molecule:before {
  content: "\e9cc";
  color: #fff;
}

.icon-icon-countries:before {
  content: "\e9cd";
  color: #fff;
}

.icon-icon-data-lab:before {
  content: "\e9ce";
  color: #fff;
}

.icon-centralized-network:before {
  content: "\e978";
  color: #fff;
}

.icon-personal-growth:before {
  content: "\e979";
  color: #fff;
}

.icon-medical-expenses-insurance:before {
  content: "\e97a";
  color: #fff;
}

.icon-stethoscope:before {
  content: "\e97b";
  color: #fff;
}

.icon-bullet-list:before {
  content: "\e9c8";
  color: #fff;
}

.icon-account_balance_wallet:before {
  content: "\e901";
}

.icon-actions:before {
  content: "\e902";
  color: #858a9c;
}

.icon-activities:before {
  content: "\e903";
}

.icon-add_box:before {
  content: "\e904";
  color: #fff;
}

.icon-apps:before {
  content: "\e905";
}

.icon-arrow_back:before {
  content: "\e906";
  color: #6861fb;
}

.icon-arrow_back_ios:before {
  content: "\e907";
  color: #b5b9ca;
}

.icon-arrow_forward:before {
  content: "\e908";
  color: #2224fb;
}

.icon-arrow_forward_ios:before {
  content: "\e909";
  color: #b5b9ca;
}

.icon-arrow-downward:before {
  content: "\e90a";
}

.icon-arrow-upward:before {
  content: "\e90b";
}

.icon-assesment:before {
  content: "\e90c";
}

.icon-bookmark:before {
  content: "\e90d";
  color: #4b84ed;
}

.icon-bookmark_border:before {
  content: "\e90e";
}

.icon-check_box:before {
  content: "\e90f";
  color: #fff;
}

.icon-check_box_outline:before {
  content: "\e910";
  color: #fff;
}

.icon-clinic:before {
  content: "\e911";
  color: #821ede;
}

.icon-close:before {
  content: "\e912";
  color: #858a9c;
}

.icon-communicable-disease:before {
  content: "\e913";
  color: #fff;
}

.icon-connection-failed .path1:before {
  content: "\e914";
  color: rgb(248, 248, 255);
}

.icon-connection-failed .path2:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-connection-failed .path3:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-connection-failed .path4:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-connection-failed .path5:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-connection-failed .path6:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-connection-failed .path7:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-connection-failed .path8:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-connection-failed .path9:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-connection-failed .path10:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-connection-failed .path11:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-connection-failed .path12:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-connection-failed .path13:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-connection-failed .path14:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-connection-failed .path15:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-connection-failed .path16:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(23, 27, 44);
}

.icon-connection-failed .path17:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-connection-failed .path18:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(37, 39, 251);
}

.icon-connection-failed .path19:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(37, 39, 251);
}

.icon-connection-failed .path20:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(37, 39, 251);
}

.icon-connection-failed .path21:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-connection-failed .path22:before {
  content: "\e9d9";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-connection-failed .path23:before {
  content: "\e9da";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-create:before {
  content: "\e92a";
  color: #858a9c;
}

.icon-disease:before {
  content: "\e92b";
  color: #fff;
}

.icon-empty .path1:before {
  content: "\e92c";
  color: rgb(103, 91, 255);
  opacity: 0.32;
}

.icon-empty .path2:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.icon-empty .path3:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(104, 97, 251);
  opacity: 0.24;
}

.icon-empty .path4:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(104, 97, 251);
  opacity: 0.24;
}

.icon-empty .path5:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(103, 91, 255);
  opacity: 0.08;
}

.icon-empty .path6:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(103, 91, 255);
  opacity: 0.08;
}

.icon-empty .path7:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-empty .path8:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(104, 97, 251);
  opacity: 0.24;
}

.icon-empty .path9:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(103, 91, 255);
  opacity: 0.08;
}

.icon-empty .path10:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(103, 91, 255);
  opacity: 0.08;
}

.icon-empty .path11:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(104, 97, 251);
  opacity: 0.24;
}

.icon-equipment:before {
  content: "\e937";
}

.icon-expand_less:before {
  content: "\e938";
}

.icon-expand_more:before, .ngx-select .ngx-select__selected .ngx-select__toggle .dropdown-toggle:before, ngx-select.small.selected_outside .ngx-select .ngx-select__selected:before {
  content: "\e939";
}

.icon-favorite:before {
  content: "\e93a";
}

.icon-featured_playlist:before {
  content: "\e93b";
}

.icon-focus:before {
  content: "\e93c";
  color: #2534f7;
}

.icon-gesture:before {
  content: "\e93d";
  color: #fff;
}

.icon-group:before {
  content: "\e93e";
}

.icon-group-add:before {
  content: "\e93f";
  color: #fff;
}

.icon-heart:before {
  content: "\e940";
}

.icon-help:before {
  content: "\e941";
}

.icon-home:before {
  content: "\e942";
  color: #fff;
}

.icon-hospital:before {
  content: "\e943";
  color: #fa6e14;
}

.icon-how-to-reg:before {
  content: "\e944";
  color: #fff;
}

.icon-indicator:before {
  content: "\e945";
}

.icon-info_outline:before {
  content: "\e946";
  color: #858a9c;
}

.icon-interventions:before {
  content: "\e947";
}

.icon-link:before {
  content: "\e948";
  color: #858a9c;
}

.icon-local_hospital:before {
  content: "\e949";
}

.icon-local_hotel:before {
  content: "\e94a";
  color: #fff;
}

.icon-location_city:before {
  content: "\e94b";
}

.icon-logo:before {
  content: "\e94c";
  color: #fff;
}

.icon-management:before {
  content: "\e94d";
}

.icon-menu:before {
  content: "\e94e";
  color: #fff;
}

.icon-mic:before {
  content: "\e94f";
}

.icon-mobile:before {
  content: "\e950";
  color: #4b84ed;
}

.icon-move_vertical:before {
  content: "\e951";
  color: #858a9c;
}

.icon-navigation:before {
  content: "\e952";
}

.icon-no-cards .path1:before {
  content: "\e953";
  color: rgb(248, 248, 255);
}

.icon-no-cards .path2:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(246, 249, 252);
}

.icon-no-cards .path3:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-no-cards .path4:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.8;
}

.icon-no-cards .path5:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(246, 249, 252);
}

.icon-no-cards .path6:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-no-cards .path7:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-no-cards .path8:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-no-cards .path9:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}

.icon-no-cards .path10:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-no-cards .path11:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-no-cards .path12:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-no-cards .path13:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-no-cards .path14:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-no-cards .path15:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-no-cards .path16:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-no-cards .path17:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-no-cards .path18:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-no-cards .path19:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-no-cards .path20:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-no-cards .path21:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(36, 39, 251);
}

.icon-no-cards .path22:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.icon-no-cards .path23:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.icon-no-cards .path24:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.icon-no-cards .path25:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(36, 39, 251);
}

.icon-no-cards .path26:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-no-cards .path27:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.icon-no-cards .path28:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(36, 39, 251);
}

.icon-no-cards .path29:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-no-cards .path30:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-notivication:before {
  content: "\e971";
}

.icon-oversight:before {
  content: "\e972";
  color: #fff;
}

.icon-pause:before {
  content: "\e973";
  color: #fff;
}

.icon-pregnant_woman:before {
  content: "\e974";
  color: #fff;
}

.icon-product_focus:before {
  content: "\e976";
  color: #fff;
}

.icon-product_analytics:before {
  content: "\e975";
  color: #fff;
}

.icon-product_profiles:before {
  content: "\e97c";
  color: #fff;
}

.icon-product_reports:before {
  content: "\e97d";
  color: #fff;
}

.icon-product_submission:before {
  content: "\e97e";
  color: #fff;
}

.icon-product_work:before {
  content: "\e97f";
  color: #fff;
}

.icon-publicon:before {
  content: "\e980";
}

.icon-reporting:before {
  content: "\e981";
  color: #fff;
}

.icon-school:before {
  content: "\e982";
}

.icon-share:before {
  content: "\e983";
  color: #858a9c;
}

.icon-site-profiles:before {
  content: "\e984";
  color: #fff;
}

.icon-skeleton .path1:before {
  content: "\e985";
  color: rgb(255, 255, 255);
}

.icon-skeleton .path2:before {
  content: "\e986";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-skeleton .path3:before {
  content: "\e987";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-skeleton .path4:before {
  content: "\e988";
  margin-left: -1em;
  color: rgb(226, 227, 231);
}

.icon-skeleton .path5:before {
  content: "\e989";
  margin-left: -1em;
  color: rgb(226, 227, 231);
}

.icon-skeleton .path6:before {
  content: "\e98a";
  margin-left: -1em;
  color: rgb(133, 138, 156);
  opacity: 0.24;
}

.icon-skeleton .path7:before {
  content: "\e98b";
  margin-left: -1em;
  color: rgb(226, 227, 231);
}

.icon-skeleton .path8:before {
  content: "\e98c";
  margin-left: -1em;
  color: rgb(226, 227, 231);
}

.icon-skeleton .path9:before {
  content: "\e98d";
  margin-left: -1em;
  color: rgb(226, 227, 231);
}

.icon-skeleton .path10:before {
  content: "\e98e";
  margin-left: -1em;
  color: rgb(104, 97, 251);
}

.icon-skeleton .path11:before {
  content: "\e98f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-skeleton .path12:before {
  content: "\e990";
  margin-left: -1em;
  color: rgb(133, 138, 156);
  opacity: 0.24;
}

.icon-skeleton .path13:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(133, 138, 156);
  opacity: 0.24;
}

.icon-social:before {
  content: "\e992";
}

.icon-socio-economic:before {
  content: "\e993";
  color: #fff;
}

.icon-something-wrong .path1:before {
  content: "\e994";
  color: rgb(248, 248, 255);
}

.icon-something-wrong .path2:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(251, 251, 253);
}

.icon-something-wrong .path3:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-something-wrong .path4:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(242, 244, 250);
}

.icon-something-wrong .path5:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-something-wrong .path6:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}

.icon-something-wrong .path7:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}

.icon-something-wrong .path8:before {
  content: "\e99b";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}

.icon-something-wrong .path9:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(251, 251, 253);
}

.icon-something-wrong .path10:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-something-wrong .path11:before {
  content: "\e99e";
  margin-left: -1em;
  color: rgb(242, 244, 250);
}

.icon-something-wrong .path12:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-something-wrong .path13:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}

.icon-something-wrong .path14:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}

.icon-something-wrong .path15:before {
  content: "\e9a2";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}

.icon-something-wrong .path16:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(251, 251, 253);
}

.icon-something-wrong .path17:before {
  content: "\e9a4";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-something-wrong .path18:before {
  content: "\e9a5";
  margin-left: -1em;
  color: rgb(242, 244, 250);
}

.icon-something-wrong .path19:before {
  content: "\e9a6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-something-wrong .path20:before {
  content: "\e9a7";
  margin-left: -1em;
  color: rgb(246, 246, 255);
}

.icon-something-wrong .path21:before {
  content: "\e9a8";
  margin-left: -1em;
  color: rgb(246, 246, 255);
}

.icon-something-wrong .path22:before {
  content: "\e9a9";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}

.icon-something-wrong .path23:before {
  content: "\e9aa";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-something-wrong .path24:before {
  content: "\e9ab";
  margin-left: -1em;
  color: rgb(36, 39, 251);
}

.icon-something-wrong .path25:before {
  content: "\e9ac";
  margin-left: -1em;
  color: rgb(246, 246, 255);
}

.icon-something-wrong .path26:before {
  content: "\e9ad";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.3;
}

.icon-something-wrong .path27:before {
  content: "\e9ae";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.3;
}

.icon-something-wrong .path28:before {
  content: "\e9af";
  margin-left: -1em;
  color: rgb(90, 90, 121);
}

.icon-something-wrong .path29:before {
  content: "\e9b0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-something-wrong .path30:before {
  content: "\e9b1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-something-wrong .path31:before {
  content: "\e9b2";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.04;
}

.icon-something-wrong .path32:before {
  content: "\e9b3";
  margin-left: -1em;
  color: rgb(246, 246, 255);
}

.icon-something-wrong .path33:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(246, 246, 255);
}

.icon-something-wrong .path34:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(246, 246, 255);
}

.icon-something-wrong .path35:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(23, 27, 44);
}

.icon-something-wrong .path36:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(75, 79, 96);
}

.icon-staff:before {
  content: "\e9b8";
  color: #fff;
}

.icon-star:before {
  content: "\e9b9";
  color: #fff;
}

.icon-stars:before {
  content: "\e9ba";
}

.icon-submit:before {
  content: "\e9bb";
  color: #fff;
}

.icon-superviced_user_circle:before {
  content: "\e9bc";
  color: #fff;
}

.icon-thumb_up:before {
  content: "\e9bd";
  color: #2534f7;
}

.icon-track_changes:before {
  content: "\e9be";
}

.icon-trophy:before {
  content: "\e9bf";
  color: #757575;
}

.icon-upload:before {
  content: "\e9c0";
}

.icon-vantage:before {
  content: "\e9c1";
  color: #757575;
}

.icon-vector:before {
  content: "\e9c2";
  color: #fff;
}

.icon-view_module:before {
  content: "\e9c3";
  color: #fff;
}

.icon-view_week:before {
  content: "\e9c4";
}

.icon-view-module:before {
  content: "\e9c5";
  color: #fff;
}

.icon-wc:before {
  content: "\e9c6";
  color: #fff;
}

.icon-web:before {
  content: "\e9c7";
  color: #fff;
}

.icon-screen_rotation:before {
  content: "\e977";
  color: #2534f7;
}

.icon-alert:before {
  content: "\e9c9";
  color: #1a4cfe;
}

.icon-show_error_log:before {
  content: "\e9cf";
}

.icon-arrow_first_ios:before {
  content: "\e9d0";
  color: #858a9c;
}

.icon-arrow_last_ios:before {
  content: "\e9d1";
  color: #858a9c;
}

.icon-pin .path1:before {
  content: "\e9d2";
  color: rgb(250, 110, 20);
  opacity: 0.1;
}

.icon-pin .path2:before {
  content: "\e9d3";
  margin-left: -1em;
  color: rgb(250, 110, 20);
}

.icon-resize-100:before {
  content: "\e9dc";
}

.icon-resize-full-screen:before {
  content: "\e9dd";
}

.icon-enlarge:before {
  content: "\e9de";
}

.icon-shrink:before {
  content: "\e9df";
}

.vantage-pattern-background {
  background-image: url("./../../assets/images/image-patterns/Vantage_Pattern_1.svg");
}