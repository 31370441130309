.map-placeholder {
	height:515px;
	max-height:calc(100vh - 242px);
	z-index:1;
	&.hidden-map {
		max-height:54px;
		height:0px;
		@include transitionfixed;
	}
	@include inverse-breakpoint('68em') {
		display:none;
	}
}
.map-container {
	position:fixed;
	top:0;
	width:100%;
	height:570px;
	max-height: calc(100vh - 186px);
	background-color:$white;
	//z-index:-1;
	.map-searchblock {
		position:absolute;
		top:78px;
		left:24px;
		height:56px;
		padding-left:48px;
		padding-right:18px;
		border-radius:4px;
		background-color:$white;
		width:330px;
		max-width:calc(100vw - 88px);
		box-shadow: 0px 3px 12px $shadowBlack;
		z-index:3;
		input {
			line-height:55px;
			background-color:transparent;
			width:100%;
			border:0 solid transparent;
			outline:none;
			font-size:16px;
			color:$greytext;
			&::placeholder {
				color:$transgreyborder;
			}
		}
		&:before, &:after {
			display:block;
			content:"";
			position:absolute;
			width:10px;
			border:2px solid $greyicon;
			height:10px;
			left:19px;
			top:19px;
			border-radius:100%;
		}
		&:after {
			width:0;
			border:1px solid $greyicon;
			transform: rotate(-45deg);
			height:8px;
			top: 29px;
    		left: 32px;
		}
	}
	.multiselect {
		position:absolute;
		color:$blue;
		background-color:$white;
		right:24px;
		bottom:24px;
		z-index:3;
		color:$blue;
		border:0 solid transparent;
		@include transitionfixed;

		span {
			@include transitionfixed;
		}
		box-shadow: 0px 3px 12px $shadowBlack;
		&.active {
			background-color:$blue;
			color:$white;

		}
		&:hover {
			background-color:$bluehover;
			color:$white;
		}
	}
  	.mapboxgl-map {
	    height:570px;
	    max-height: calc(100vh - 186px);
	    @include inverse-breakpoint('40em') {
	    	max-height:calc(100vh - 110px);
	    }
  	}
  	// tablet
  	@include inverse-breakpoint('68em') {
  		position:fixed;
  		height:calc(100vh - 90px);
  		max-height:calc(100vh - 90px);
  		top:0;
  		.breadcrumbs-container {
  			.right {
  				display:none;
  			}
  		}
  		.mapboxgl-map {
  			height:calc(100vh - 90px);
  			max-height:calc(100vh - 90px);
  		}
  		.map-searchblock {
  			//max-width:206px;
  			left:16px;
  			height: 48px;
  			line-height:48px;
  			width:calc(100vw - 240px);
  			input {
  				line-height:48px;
  			}
  			&:before
			{
				top:15px;
			}
			&:after {
				top: 25px;
			}
		}
  		.multiselect {
  			bottom:auto;
  			//right:auto;
  			top:78px;
  			//left:296px;
  			height:48px;
  			width:48px;

  			right: 16px;
    		left: auto;
  			&:hover, &.active {
  				background-color:$orange;
	  			.icon-layers {
	  				span {
	  					background-color:$white;
	  					&:before, &:after {
	  						background-color:$orange;
	  					}
	  				}
					&:before {
						background-color:$white;
					}
					&:after {
						border-bottom:2px solid $white;
						border-left:2px solid $white;
					}
				}
  			}
  		}
		.mapboxgl-ctrl-group {
			.mapboxgl-ctrl-zoom-in, .mapboxgl-ctrl-zoom-out {
				display:none;
			}
		}
  	}
	@include inverse-breakpoint('30em') {
		.multiselect {
			left:auto;
			right:16px;
		}
	}
}

  	.breadcrumbs-container {
  		background-color:$white;
  		height:54px;
  		border-bottom:1px solid $greyborder;
		position: sticky;
		top: 0;
		width: 100%;
		z-index: 6;
  		.back {
  			background-color:$white;
  			float:left;
  			border:0 solid transparent;
  			border-radius:0;
  			border-right:1px solid $greyborder;
  			height:54px;
  			color:$greytext;
  			padding-top:0;
  			padding-bottom:0;
  			padding-left:52px;
  			padding:0 27px 0 52px;
  			font-size:12px;
  			line-height:54px;
  			position:relative;
  			margin-right: 3px;
  			&:before, &:after {
  				content:"";
  				position:absolute;
  				display:block;
  			}
  			&:before {
  				width:10px;
  				height:10px;
  				left:28px;
				border-left:2px solid $midgrey;
				border-top:2px solid $midgrey;
				transform: rotate(-45deg);
  			}
  			&:after {
  				width:12px;
  				height:2px;
  				background-color:$midgrey;
  				left:29px;
  			}
  		}
		.breadcrumbs {
			
			.connector {
				display:inline-block;
				width:5px;
				position:relative;
				&:before {
					content:"";
					display:block;
					width:6px;
					height:6px;
					top:-10px;
					left:-4px;
					position:absolute;
					border-right:2px solid $midgrey;
					border-bottom:2px solid $midgrey;
					transform: rotate(-45deg);
				}
			}
			a {
				line-height:54px;
				display:inline-block;
				padding:0 14px;
				text-decoration:none;
				color:$midgrey;
				&:last-of-type {
					color:$text-color;
				}
			}
		}
		.right {
			margin-right:24px;
			margin-top:7px;
			.icon-globe-blue {
				display:inline;
			}
		}
		.rightbtn {
			max-height:40px;
		}
		@include inverse-breakpoint('40em') {
			.back {
				width:55px;
				padding:0;
				&:before {
					left:24px;
				}
				&:after {
					left:25px;
				}
			}
			.breadcrumbs {
				white-space: nowrap;
				a {
					font-size:12px;
					padding:0 6px;
				}
				.connector {
					&:before {
						top:-9px;
					}
				}
			}
		}
		
	  }
.map-widget {
	border-radius:4px;
	z-index:5;
	position:fixed;
	box-shadow: 0px 3px 12px $shadowBlack;
	width:auto;
	background-color:$white;
	padding:10px;
}
.info {
	border-radius:4px;
	z-index:5;
		position:fixed;
		right:24px;
		top: 156px;
	box-shadow: 0px 3px 12px $shadowBlack;
	width:268px;
	padding-top:17px;
	background-color:$white;
	h1 {
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
		padding: 0px 20px;
		margin:0;
	}
	.closebtn {
		position:absolute;
		top:21px;
		right:23px;
		width:14px;
		height:14px;
		&:before, &:after {
			content:"";
			display:block;
			position:absolute;
			width:2px;
			height:16px;
			background-color:$disabledgrey;
			top:-2px;
			left:5px;
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
	}
	.placeholder {
		display:none;
		text-align:center;
		padding-top:70px;
		padding-bottom:74px;
		.icon-info {
			margin:0 auto;
		}
		p {
			font-size:14px;
			margin:0;
			margin-top:10px;
			color:$greytext;
			line-height:20px;
		}
	}
	.stats {
		.section {
			position: relative;
			padding: 15px 20px 16px 20px;
			border-bottom:1px solid $greyborder;
			display:flex;

			&:last-of-type {
				border-bottom:0 solid transparent;
			}
			&.indicator1 {
				h5 {
					color:$blue;
				}
			}
			&.indicator2 {
				h5 {
					color:$yellow;
				}
			}
			&.indicator3 {
				h5 {
					color:$red;
				}
			}
			> div {
			    order: 0;
			    flex: 0 1 50%;
			    align-self: auto;
			}
			> h5 {
				text-align:right;
				white-space: nowrap;
			    flex: 1 1 auto;
			}
		}
		h4 {
			font-weight: normal;
			font-size: 14px;
			line-height: 20px;
			margin:0;
			margin-bottom:0px;
		}
		small {
			color:$greytext;
			font-size: 10px;
		}
		h5 {
			font-size:16px;
			font-weight: 600;
			margin:0;
		}
	}
	&.empty {
		.placeholder {
			display:block;
		}
		.stats {
			display:none;
		}
	}
	@include inverse-breakpoint('68em') {
		width:100%;
		right:auto;
		top:auto;
		left:0;
		bottom:0;
		z-index:13;
		border-radius: 16px 16px 0px 0px;
		padding-top:30px;
		box-shadow: 0px 0px 12px $shadowBlack;
		.closebtn {
			width:100%;
			height:30px;
			top:0;
			right:0;
			&:before {
				position:relative;
				margin:10px auto 0;
				background-color:$disabledgrey;
				width:24px;
				height:5px;
				border-radius:2.5px;
				transform: rotate(0deg);
			}
			&:after {
				display:none;
			}
		}
	}
	&.dark {
		color:$white;
		background-color:$text-color;
		padding:16px;
		box-sizing: border-box;
		width:284px;
		p {
			margin-top:0;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 20px;
			&:last-of-type {
				margin-bottom:0;
			}
		}
		@include inverse-breakpoint('68em') {
			width:100%;
			right:auto;
			top:auto;
			left:0;
			bottom:0;
			z-index:13;
			border-radius: 16px 16px 0px 0px;
			padding-top:30px;
			box-shadow: 0px 0px 12px $shadowBlack;
		}
	}
}


.legend {
	position:absolute;
	left:24px;
	bottom:24px;
	width:56px;
	height:56px;
	border-radius:4px;
	box-shadow: 0px 3px 12px a(2, 4, 16, 0.15);
	background-color:$white;
	overflow:hidden;
	@include transitionfixed;
	&.open {
		width:242px;
		button {
			background-color:$tintblue;
			&:hover {
				background-color:$blue;
				span {
					&:before, &:after {
						background-color:$white;
					}
				}
			}
		}
	}
	button {
		display:block;
		width:56px;
		height:56px;
		border:0 solid transparent;
		padding:19px 18px;
		background-color:$white;
		&:hover {
			background-color:$tintblue;
		}
		span {
		    display: flex;
		    flex-direction: row;
		    flex-wrap: nowrap;
		    justify-content: space-between;
		    align-content: stretch;
		    align-items: center;
			width:20px;
			height:4px;
			margin-bottom:3px;
			&:before {
				content:"";
				border-radius:4px;
				width:4px;
				height:4px;
				display:block;
				background-color:$blue;
				@include transitionfixed;
			}
			&:after {
				width:14px;
				height:2px;
				content:"";
				display:block;
				background-color:$blue;
				@include transitionfixed;
			}
		}
	}
	.legend-info {
		padding:9px 12px;
		position:absolute;
		left:56px;
		top:0;
		width:162px;
	    display: flex;
	    flex-direction: row;
	    flex-wrap: nowrap;
	    justify-content: space-between;
	    align-content: stretch;
	    align-items: flex-end;
		> div {
			width:66px;
		}
		h5 {
			font-size:8px;
			line-height:8px;
			color:$greytext;
			font-weight:normal;
			margin-top:0;
			margin-bottom:0;
		}
		.gradient {
			background: linear-gradient(90deg, #e5e7fe 0%, #7c85fa 100%);
			display:block;
			width:66px;
			height:10px;
			border-radius:2px;
			margin-top:8px;
		}
		.labels {
			margin-top:3px;
		    display: flex;
		    flex-direction: row;
		    flex-wrap: nowrap;
		    justify-content: space-between;
		    align-content: stretch;
		    align-items: center;
			span {
				font-size:8px;
				line-height:8px;
				color:$greytext;
				font-weight:normal;
				margin-top:0;
				margin-bottom:0;
			}
		}
		.circles {
			width:66px;
		    display: flex;
		    flex-direction: row;
		    flex-wrap: nowrap;
		    justify-content: space-between;
		    align-content: stretch;
		    align-items: flex-end;
			span {
				border:1px solid $tintorange;
				background:$tintorange;
				display:block;
				border-radius:100%;
				width:12px;
				height:12px;
				&:first-of-type {
					width:6px;
					height:6px;
				}
				&:last-of-type {
					width:16px;
					height:16px;
				}
			}
		}
	}

	.priority-wrapper {
		width:100%;
	    flex: 1 1 auto;
	}
	.priority {
		display:grid;
		height:9px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr;
		gap: 0px 2px;
		margin:6px 0;
		span {
			height:100%;
			display: block;
			&:nth-of-type(1) {
				background-color:$gradiated1;
			}
			&:nth-of-type(2) {
				background-color:$gradiated2;
			}
			&:nth-of-type(3) {
				background-color:$gradiated3;
			}
			&:nth-of-type(4) {
				background-color:$gradiated4;
			}
			&:nth-of-type(5) {
				background-color:$gradiated5;
			}
		}
	}
	.priority-3 {
		display:grid;
		height:9px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr;
		gap: 0px 2px;
		margin:6px 0;
		span {
			height:100%;
			display: block;
			&:nth-of-type(1) {
				background-color:$gradiated1;
			}
			&:nth-of-type(2) {
				background-color:$gradiated3;
			}
			&:nth-of-type(3) {
				background-color:$gradiated5;
			}
		}
	}
	@include inverse-breakpoint('68em') {
		left:16px;
		//right:16px;
		bottom:40px;
		//top:134px;
		width:48px;
		height:48px;
		button {
			//position:absolute;
			//right:0;
			width:48px;
			height:48px;
			padding: 15px 14px;
			&:hover {
				background-color:$white;
				span {
					&:before, &:after {
						background-color:$blue;
					}
				}
			}
		}
		&.open {
			width:236px;
			button {
				&:hover {
					background-color:$tintblue;
					span {
						&:before, &:after {
							background-color:$blue;
						}
					}
				}
			}
		}
		.legend-info {
			left:48px;
			width:164px;
			//width:calc(100% - 72px);
			padding: 6px 12px;
		}
		&.focus-legend {
			top:72px;
			bottom:auto;
			left:auto;
			right:24px;
			button {
				float:right;
			}
			.legend-info {
				left:auto;
				right:48px;
			}
		}
	}
}

.time-selector {
	position:absolute;
	top:78px;
	left:24px;
	height:45px;
	box-sizing:border-box;
	padding-left:48px;
	padding-top:15px;
	padding-right:18px;
	border-radius:4px;
	background-color:$white;
	padding-right:56px;
	max-width:calc(100vw - 88px);
	box-shadow: 0px 3px 12px $shadowBlack;
	z-index:3;
	display:block;
	cursor:pointer;
	.calendar-icon {
		position:absolute;
		left:19px;
		top:13px;
	}

	&:after {
		display:block;
		content:"";
		position:absolute;
		border:5px solid transparent;
		border-top:5px solid $greyicon;
		top:20px;
		right:23px;
	}
	label {
		font-size:14px;
		line-height:16px;
		display:block;
		color:$ebonyclay;
	}
	&:hover, &.open {
		.calendar-icon {
			border-color: $blue;
			span {
				border-left:2px solid $blue;
				border-right:2px solid $blue;
				&:before {
					background-color:$blue;
				}
			}
			&:before {
				border-left:2px solid $blue;
				border-right:2px solid $blue;
			}
		}
	}
	&.open {
		&:after {
			top:15px;
			transform: rotate(180deg);
		}
	}
	.time-list {
		position:absolute;
		box-shadow: 0px 3px 12px $shadowBlack;
		top:37px;
		left:0px;
		width:200px;
		border-radius:4px;
		display:none;
		background-color:$white;
		padding:0;
		height:136px;
		overflow:hidden;
		@include transitionfixed;
		li {
			display:block;
			height:40px;
			line-height:40px;
			padding:0px 16px;
			@include transitionfixed;
			font-size:14px;
			cursor:pointer;
			color:$ebonyclay;
			&:first-of-type {
				margin-top:4px;
			}
			&:hover {
				background-color:$hovergrey;
			}
		}
	}
}

.map-number {
	position: absolute;
	min-width:16px;
	height:20px;
	border:1px solid $blue;
	background-color:$white;
	display:block;
	line-height:20px;
	border-radius:6px;
	font-size:9px;
	text-align:center;
	cursor:pointer;
	text-decoration:none;
	color:$base-color;
	padding:0 2px;
	.types-list {
		display:none;
		left:0;
		top: 30px;
		padding-right:12px;
		position:absolute;;
		> div {
			margin-bottom:8px;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-content: flex-start;
			align-items: center;
			i {
				width:14px;
				display:inline-block;
				margin-right:8px;
				height:15px;
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				justify-content: center;
				align-content: center;
				align-items: center;
			}
			span {
				font-size:12px;
				line-height:15px;
				white-space: nowrap;
			}
			&:last-of-type {
				margin-bottom:0;
			}
		}
	}
}