@use "sass:math";
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

@import 'bootstrap/scss/mixins/breakpoints';
.modal-body {
  @include v4_text_subtitle_2();
  padding: 32px 24px;
  color: rgba(23, 27, 44, 0.6);
}

.modal-header {
  h4 {
    @include v4_text_subtitle_2();
    margin: 0;
  }

  button {
    font-size: 24px;
    color: rgba(133, 138, 156, 0.6);
    padding: 5px;
  }
}

.modal-footer {
  justify-content: space-between;

  .buttons-right-container {
    display: flex;
    align-items: center;
    flex-direction: row;

    button {
      margin-left: 10px;
    }
  }
}

.allign-footer-right {
  .modal-footer {
    justify-content: flex-end;
  }
}

.modal-backdrop.show {
  background: #171b2c;
  mix-blend-mode: normal;
  opacity: 0.66;
}
@include media-breakpoint-down(sm) {
  .modal-dialog {
    height: auto;
    min-height: 100%;
    max-width: none;
    margin: 0;
    border-radius: 0;

    &.no-border__mobile .modal-content {
      border: none !important;
      border-radius: 0 !important;
    }

    .modal-content {
      height: 100%;
    }

    .modal-body {
      padding: 32px 24px;
      flex: none;
    }

    .modal-footer {
      border-top: none;
      flex-direction: column;

      .buttons-right-container {
        flex-direction: column-reverse;
        padding: 22px 24px;
        width: 100%;
      }

      button {
        margin: 0;
        margin-bottom: 10px;
        justify-content: center;
        width: 100%;
      }
    }
  }
}

.modal-dialog.not-supported-orientation-modal {

  @mixin center-items-horizontally() {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  $modal-height: 250px;

  max-width: 280px;
  max-height: $modal-height !important;
  margin: calc(50vh - math.div($modal-height, 2)) auto;
  border-radius: 8px;

  .modal-content {
    @include center-items-horizontally();
    padding: 24px;
    border-radius: 8px;
  }

  .message-text {
    @include center-items-horizontally();
  }

  h3 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $black_pearl;
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $black_pearl_6;
  }

  .btn-block {
    @include center-items-horizontally();
    //padding: 10px 16px;
  }
}
