@font-face {
  font-family: 'icomoon';
  /*src: url('~/assets/images/fonts/icomoon.eot');*/
  src: url('./../../assets/images/fonts/icomoon.eot');
  src: url('./../../assets/images/fonts/icomoon.eot') format('embedded-opentype'),
    url('./../../assets/images/fonts/icomoon.ttf') format('truetype'),
    url('./../../assets/images/fonts/icomoon.woff') format('woff'),
    url('./../../assets/images/fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-delete_outline:before {
  content: "\e9db";
  color: #858a9c;
}

.icon-add:before {
  content: "\e900";
  color: #fff;
}
.icon-cancel:before {
  content: "\e915";
}
.icon-add_circle:before {
  content: "\e9d4";
  color: #fff;
}
.icon-file_download:before {
  content: "\e9d5";
}
.icon-sent:before {
  content: "\e9d6";
  color: #fff;
}
.icon-parent_node:before {
  content: "\e9d7";
  color: #fff;
}
.icon-product_matching:before {
  content: "\e9d8";
  color: #fff;
}
.icon-icon-funders:before {
  content: "\e9ca";
  color: #fff;
}
.icon-icon-manufacturer:before {
  content: "\e9cb";
  color: #fff;
}
.icon-icon-molecule:before {
  content: "\e9cc";
  color: #fff;
}
.icon-icon-countries:before {
  content: "\e9cd";
  color: #fff;
}
.icon-icon-data-lab:before {
  content: "\e9ce";
  color: #fff;
}
.icon-centralized-network:before {
  content: "\e978";
  color: #fff;
}
.icon-personal-growth:before {
  content: "\e979";
  color: #fff;
}
.icon-medical-expenses-insurance:before {
  content: "\e97a";
  color: #fff;
}
.icon-stethoscope:before {
  content: "\e97b";
  color: #fff;
}
.icon-bullet-list:before {
  content: "\e9c8";
  color: #fff;
}
.icon-account_balance_wallet:before {
  content: "\e901";
}
.icon-actions:before {
  content: "\e902";
  color: #858a9c;
}
.icon-activities:before {
  content: "\e903";
}
.icon-add_box:before {
  content: "\e904";
  color: #fff;
}
.icon-apps:before {
  content: "\e905";
}
.icon-arrow_back:before {
  content: "\e906";
  color: #6861fb;
}
.icon-arrow_back_ios:before {
  content: "\e907";
  color: #b5b9ca;
}
.icon-arrow_forward:before {
  content: "\e908";
  color: #2224fb;
}
.icon-arrow_forward_ios:before {
  content: "\e909";
  color: #b5b9ca;
}
.icon-arrow-downward:before {
  content: "\e90a";
}
.icon-arrow-upward:before {
  content: "\e90b";
}
.icon-assesment:before {
  content: "\e90c";
}
.icon-bookmark:before {
  content: "\e90d";
  color: #4b84ed;
}
.icon-bookmark_border:before {
  content: "\e90e";
}
.icon-check_box:before {
  content: "\e90f";
  color: #fff;
}
.icon-check_box_outline:before {
  content: "\e910";
  color: #fff;
}
.icon-clinic:before {
  content: "\e911";
  color: #821ede;
}
.icon-close:before {
  content: "\e912";
  color: #858a9c;
}
.icon-communicable-disease:before {
  content: "\e913";
  color: #fff;
}
.icon-connection-failed .path1:before {
  content: "\e914";
  color: rgb(248, 248, 255);
}
.icon-connection-failed .path2:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-connection-failed .path3:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-connection-failed .path4:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-connection-failed .path5:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-connection-failed .path6:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-connection-failed .path7:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-connection-failed .path8:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.0400;
}
.icon-connection-failed .path9:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.0400;
}
.icon-connection-failed .path10:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.0400;
}
.icon-connection-failed .path11:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.0400;
}
.icon-connection-failed .path12:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.0400;
}
.icon-connection-failed .path13:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.0400;
}
.icon-connection-failed .path14:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.0400;
}
.icon-connection-failed .path15:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-connection-failed .path16:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(23, 27, 44);
}
.icon-connection-failed .path17:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-connection-failed .path18:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(37, 39, 251);
}
.icon-connection-failed .path19:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(37, 39, 251);
}
.icon-connection-failed .path20:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(37, 39, 251);
}
.icon-connection-failed .path21:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-connection-failed .path22:before {
  content: "\e9d9";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-connection-failed .path23:before {
  content: "\e9da";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-create:before {
  content: "\e92a";
  color: #858a9c;
}
.icon-disease:before {
  content: "\e92b";
  color: #fff;
}
.icon-empty .path1:before {
  content: "\e92c";
  color: rgb(103, 91, 255);
  opacity: 0.32;
}
.icon-empty .path2:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.icon-empty .path3:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(104, 97, 251);
  opacity: 0.24;
}
.icon-empty .path4:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(104, 97, 251);
  opacity: 0.24;
}
.icon-empty .path5:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(103, 91, 255);
  opacity: 0.0800;
}
.icon-empty .path6:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(103, 91, 255);
  opacity: 0.0800;
}
.icon-empty .path7:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-empty .path8:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(104, 97, 251);
  opacity: 0.24;
}
.icon-empty .path9:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(103, 91, 255);
  opacity: 0.0800;
}
.icon-empty .path10:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(103, 91, 255);
  opacity: 0.0800;
}
.icon-empty .path11:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(104, 97, 251);
  opacity: 0.24;
}
.icon-equipment:before {
  content: "\e937";
}
.icon-expand_less:before {
  content: "\e938";
}
.icon-expand_more:before {
  content: "\e939";
}
.icon-favorite:before {
  content: "\e93a";
}
.icon-featured_playlist:before {
  content: "\e93b";
}
.icon-focus:before {
  content: "\e93c";
  color: #2534f7;
}
.icon-gesture:before {
  content: "\e93d";
  color: #fff;
}
.icon-group:before {
  content: "\e93e";
}
.icon-group-add:before {
  content: "\e93f";
  color: #fff;
}
.icon-heart:before {
  content: "\e940";
}
.icon-help:before {
  content: "\e941";
}
.icon-home:before {
  content: "\e942";
  color: #fff;
}
.icon-hospital:before {
  content: "\e943";
  color: #fa6e14;
}
.icon-how-to-reg:before {
  content: "\e944";
  color: #fff;
}
.icon-indicator:before {
  content: "\e945";
}
.icon-info_outline:before {
  content: "\e946";
  color: #858a9c;
}
.icon-interventions:before {
  content: "\e947";
}
.icon-link:before {
  content: "\e948";
  color: #858a9c;
}
.icon-local_hospital:before {
  content: "\e949";
}
.icon-local_hotel:before {
  content: "\e94a";
  color: #fff;
}
.icon-location_city:before {
  content: "\e94b";
}
.icon-logo:before {
  content: "\e94c";
  color: #fff;
}
.icon-management:before {
  content: "\e94d";
}
.icon-menu:before {
  content: "\e94e";
  color: #fff;
}
.icon-mic:before {
  content: "\e94f";
}
.icon-mobile:before {
  content: "\e950";
  color: #4b84ed;
}
.icon-move_vertical:before {
  content: "\e951";
  color: #858a9c;
}
.icon-navigation:before {
  content: "\e952";
}
.icon-no-cards .path1:before {
  content: "\e953";
  color: rgb(248, 248, 255);
}
.icon-no-cards .path2:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(246, 249, 252);
}
.icon-no-cards .path3:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-no-cards .path4:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.8;
}
.icon-no-cards .path5:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(246, 249, 252);
}
.icon-no-cards .path6:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-no-cards .path7:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-no-cards .path8:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-no-cards .path9:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-no-cards .path10:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-no-cards .path11:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-no-cards .path12:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-no-cards .path13:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-no-cards .path14:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.0400;
}
.icon-no-cards .path15:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.0400;
}
.icon-no-cards .path16:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.0400;
}
.icon-no-cards .path17:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.0400;
}
.icon-no-cards .path18:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.0400;
}
.icon-no-cards .path19:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-no-cards .path20:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-no-cards .path21:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(36, 39, 251);
}
.icon-no-cards .path22:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.icon-no-cards .path23:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.icon-no-cards .path24:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.icon-no-cards .path25:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(36, 39, 251);
}
.icon-no-cards .path26:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-no-cards .path27:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.icon-no-cards .path28:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(36, 39, 251);
}
.icon-no-cards .path29:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.0400;
}
.icon-no-cards .path30:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.0400;
}
.icon-notivication:before {
  content: "\e971";
}
.icon-oversight:before {
  content: "\e972";
  color: #fff;
}
.icon-pause:before {
  content: "\e973";
  color: #fff;
}
.icon-pregnant_woman:before {
  content: "\e974";
  color: #fff;
}
.icon-product_focus:before {
  content: "\e976";
  color: #fff;
}
.icon-product_analytics:before {
  content: "\e975";
  color: #fff;
}
.icon-product_profiles:before {
  content: "\e97c";
  color: #fff;
}
.icon-product_reports:before {
  content: "\e97d";
  color: #fff;
}
.icon-product_submission:before {
  content: "\e97e";
  color: #fff;
}
.icon-product_work:before {
  content: "\e97f";
  color: #fff;
}
.icon-publicon:before {
  content: "\e980";
}
.icon-reporting:before {
  content: "\e981";
  color: #fff;
}
.icon-school:before {
  content: "\e982";
}
.icon-share:before {
  content: "\e983";
  color: #858a9c;
}
.icon-site-profiles:before {
  content: "\e984";
  color: #fff;
}
.icon-skeleton .path1:before {
  content: "\e985";
  color: rgb(255, 255, 255);
}
.icon-skeleton .path2:before {
  content: "\e986";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-skeleton .path3:before {
  content: "\e987";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-skeleton .path4:before {
  content: "\e988";
  margin-left: -1em;
  color: rgb(226, 227, 231);
}
.icon-skeleton .path5:before {
  content: "\e989";
  margin-left: -1em;
  color: rgb(226, 227, 231);
}
.icon-skeleton .path6:before {
  content: "\e98a";
  margin-left: -1em;
  color: rgb(133, 138, 156);
  opacity: 0.24;
}
.icon-skeleton .path7:before {
  content: "\e98b";
  margin-left: -1em;
  color: rgb(226, 227, 231);
}
.icon-skeleton .path8:before {
  content: "\e98c";
  margin-left: -1em;
  color: rgb(226, 227, 231);
}
.icon-skeleton .path9:before {
  content: "\e98d";
  margin-left: -1em;
  color: rgb(226, 227, 231);
}
.icon-skeleton .path10:before {
  content: "\e98e";
  margin-left: -1em;
  color: rgb(104, 97, 251);
}
.icon-skeleton .path11:before {
  content: "\e98f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-skeleton .path12:before {
  content: "\e990";
  margin-left: -1em;
  color: rgb(133, 138, 156);
  opacity: 0.24;
}
.icon-skeleton .path13:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(133, 138, 156);
  opacity: 0.24;
}
.icon-social:before {
  content: "\e992";
}
.icon-socio-economic:before {
  content: "\e993";
  color: #fff;
}
.icon-something-wrong .path1:before {
  content: "\e994";
  color: rgb(248, 248, 255);
}
.icon-something-wrong .path2:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(251, 251, 253);
}
.icon-something-wrong .path3:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-something-wrong .path4:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(242, 244, 250);
}
.icon-something-wrong .path5:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-something-wrong .path6:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}
.icon-something-wrong .path7:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}
.icon-something-wrong .path8:before {
  content: "\e99b";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}
.icon-something-wrong .path9:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(251, 251, 253);
}
.icon-something-wrong .path10:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-something-wrong .path11:before {
  content: "\e99e";
  margin-left: -1em;
  color: rgb(242, 244, 250);
}
.icon-something-wrong .path12:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-something-wrong .path13:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}
.icon-something-wrong .path14:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}
.icon-something-wrong .path15:before {
  content: "\e9a2";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}
.icon-something-wrong .path16:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(251, 251, 253);
}
.icon-something-wrong .path17:before {
  content: "\e9a4";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-something-wrong .path18:before {
  content: "\e9a5";
  margin-left: -1em;
  color: rgb(242, 244, 250);
}
.icon-something-wrong .path19:before {
  content: "\e9a6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-something-wrong .path20:before {
  content: "\e9a7";
  margin-left: -1em;
  color: rgb(246, 246, 255);
}
.icon-something-wrong .path21:before {
  content: "\e9a8";
  margin-left: -1em;
  color: rgb(246, 246, 255);
}
.icon-something-wrong .path22:before {
  content: "\e9a9";
  margin-left: -1em;
  color: rgb(233, 236, 246);
}
.icon-something-wrong .path23:before {
  content: "\e9aa";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-something-wrong .path24:before {
  content: "\e9ab";
  margin-left: -1em;
  color: rgb(36, 39, 251);
}
.icon-something-wrong .path25:before {
  content: "\e9ac";
  margin-left: -1em;
  color: rgb(246, 246, 255);
}
.icon-something-wrong .path26:before {
  content: "\e9ad";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.3;
}
.icon-something-wrong .path27:before {
  content: "\e9ae";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.3;
}
.icon-something-wrong .path28:before {
  content: "\e9af";
  margin-left: -1em;
  color: rgb(90, 90, 121);
}
.icon-something-wrong .path29:before {
  content: "\e9b0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-something-wrong .path30:before {
  content: "\e9b1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-something-wrong .path31:before {
  content: "\e9b2";
  margin-left: -1em;
  color: rgb(34, 36, 251);
  opacity: 0.0400;
}
.icon-something-wrong .path32:before {
  content: "\e9b3";
  margin-left: -1em;
  color: rgb(246, 246, 255);
}
.icon-something-wrong .path33:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(246, 246, 255);
}
.icon-something-wrong .path34:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(246, 246, 255);
}
.icon-something-wrong .path35:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(23, 27, 44);
}
.icon-something-wrong .path36:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(75, 79, 96);
}
.icon-staff:before {
  content: "\e9b8";
  color: #fff;
}
.icon-star:before {
  content: "\e9b9";
  color: #fff;
}
.icon-stars:before {
  content: "\e9ba";
}
.icon-submit:before {
  content: "\e9bb";
  color: #fff;
}
.icon-superviced_user_circle:before {
  content: "\e9bc";
  color: #fff;
}
.icon-thumb_up:before {
  content: "\e9bd";
  color: #2534f7;
}
.icon-track_changes:before {
  content: "\e9be";
}
.icon-trophy:before {
  content: "\e9bf";
  color: #757575;
}
.icon-upload:before {
  content: "\e9c0";
}
.icon-vantage:before {
  content: "\e9c1";
  color: #757575;
}
.icon-vector:before {
  content: "\e9c2";
  color: #fff;
}
.icon-view_module:before {
  content: "\e9c3";
  color: #fff;
}
.icon-view_week:before {
  content: "\e9c4";
}
.icon-view-module:before {
  content: "\e9c5";
  color: #fff;
}
.icon-wc:before {
  content: "\e9c6";
  color: #fff;
}
.icon-web:before {
  content: "\e9c7";
  color: #fff;
}
.icon-screen_rotation:before {
  content: "\e977";
  color: #2534f7;
}
.icon-alert:before {
  content: "\e9c9";
  color: #1a4cfe;
}
.icon-show_error_log:before {
  content: "\e9cf";
}
.icon-arrow_first_ios:before {
  content: "\e9d0";
  color: #858a9c;
}
.icon-arrow_last_ios:before {
  content: "\e9d1";
  color: #858a9c;
}
.icon-pin .path1:before {
  content: "\e9d2";
  color: rgb(250, 110, 20);
  opacity: 0.1;
}
.icon-pin .path2:before {
  content: "\e9d3";
  margin-left: -1em;
  color: rgb(250, 110, 20);
}
.icon-resize-100:before {
  content: "\e9dc";
}
.icon-resize-full-screen:before {
  content: "\e9dd";
}
.icon-enlarge:before {
  content: "\e9de";
}
.icon-shrink:before {
  content: "\e9df";
}

.vantage-pattern-background {
  background-image: url('./../../assets/images/image-patterns/Vantage_Pattern_1.svg');
}
