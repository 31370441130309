.lightbox-container {
	background-color:$transgreybg;
	display:none;
	position:fixed;
	z-index:100;
	width:100vw;
	height:100vh;
	top:0;
	left:0;
	overflow:scroll;
	padding-top:40px;
}
.lightbox {
	background-color:$white;
	border-radius:8px;
	margin:0 auto;
	margin-bottom:100px;
	max-width:95vw;
	position:relative;
	.closebtn {
		position:absolute;
		top:24px;
		right:24px;
	}
	&.small {
		width:400px;
	}
	.card-header {
		position:relative;
		color:$text-color;
		font-weight:500;
		font-size:16px;
		padding:22px 22px;
		&.breadcrumbs-container {
			padding:0 20px;
			background-color:transparent;
		}
	}
}
.map-lightbox-container {
	.lightbox {
		height:calc(100vh - 94px);
		.map {
			height:calc(100vh - 134px);
			border-radius:0 0 8px 8px;
		}
		.closebtn {
			width:56px;
			height:56px;
			display:block;
			background-color: $white;
			top:78px;
			box-shadow: 0px 3px 12px $shadowBlack;
			right:24px;
			border-radius:100%;
			border:0 solid transparent;
			z-index:12;
			&:before, &:after {
				height:24px;
				top: 16px;
	    		left: 27px;
			}
		}
	}
	@include inverse-breakpoint('68em') {
		padding:0;
		top:56px;
		padding:0;
		.lightbox  {
			margin:0;
			max-width:100vw;
			border-radius:0;
			height:calc(100vh - 56px);
			.map {
				height:calc(100vh - 109px);
				border-radius:0;
			}
		}

		.closebtn {
			top:auto;
			bottom:24px;
		}
	}
}
.small-lightbox {
	max-width:420px;
	text-align:center;
	section {
		padding:40px;
		display:grid;
		grid-gap: 1.5rem;
		height: auto;
		margin-left: 0;
		overflow: auto;
		p {
			margin:0;
		}
	}
	.button-container {
		margin:0 auto;
		button {
			display:inline-block;
			margin-left:0.5rem;
			&:first-of-type {
				margin-left:0;
			}
		}
	}
}