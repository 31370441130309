@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

@import 'bootstrap/scss/mixins/breakpoints';

section.page {
  height: calc(100vh - 20px);
  margin-left: 88px;
  transition: 0.5s;

  overflow: hidden;
  transition: all 0.25s ease-in-out;

  @include media-breakpoint-down(lg) {
    margin-left: 0;
  }

  &.headerVisible {
    margin-top: 80px;
    height: calc(100vh - 80px);

    v4-header {
      top: 0px;
    }

    .focus-cards-wrapper,
    .collections-body-wrapper {
      height: calc(100vh - 150px);
    }

    .focus-heade {
      margin-bottom: 0;
    }
  }

  .main-content {
    max-width: 1040px;
    margin: 50px auto;
  }
}

vp-side-nav.open + section.page {
  @import "bootstrap/scss/functions";
  @import "bootstrap/scss/variables";
  @import "bootstrap/scss/mixins";

  @include media-breakpoint-up(lg) {
    margin-left: 320px;
  }
}

:root {
  --microtip-transition-duration: 0.1s;
  --microtip-transition-delay: 0.5s;
  --microtip-transition-easing: ease-out;
  --microtip-font-size: 14px;
  --microtip-font-weight: 400;
  --microtip-text-transform: none;
}
