@import './variables';
@import './mixins';

%btn {
  @include v4_text_button();
  background: $button_bg;
  border: 1px solid $button_border_color;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;

  -webkit-tap-highlight-color: transparent;

  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
}

button {
  @extend %btn;

  background: $button_contained_bg;
  color: $button_contained_color;

  &:hover {
    background-color: $button_contained_focus_bg;
  }

  &:active {
    background-color: $button_contained_active_bg;
  }

  &:disabled {
    background-color: $button_contained_disabled_bg;
    border-color: $button_contained_disabled_border_color;
    pointer-events: none;
  }

  &.round {
    border-radius: 50px;
    padding: 10px;
  }

  &.small {
    font-size: 10px;
    line-height: 10px;
    padding: 8px 9px;
  }

  &.outline {
    background: $button_outline_bg;
    color: $button_outline_color;
    border: 1px solid $button_outline_border_color;

    &:focus,
    &:hover {
      background-color: $button_outline_focus_bg;
    }

    &:active {
      background-color: $button_outline_active_bg;
    }

    &:disabled {
      background-color: $button_outline_disabled_bg;
      border-color: $button_outline_disabled_border_color;
      pointer-events: none;
    }
  }

  &.text {
    background: $button_text_bg;
    color: $button_text_color;
    border: 1px solid $button_text_border_color;

    &:focus,
    &:hover {
      background-color: $button_text_focus_bg;
    }

    &:active {
      background-color: $button_text_active_bg;
    }

    &:disabled {
      background-color: $button_text_disabled_bg;
      border-color: $button_text_disabled_border_color;
      pointer-events: none;
    }
  }
}

/* ICON button */
button.icon-btn {
  border: none;
  outline: none;
  background: none;
  padding: 7px 10px;
  border-radius: 50%;

  &.outline {
    border: 1px solid $mystic;
  }

  .icon:before {
    font-size: 20px;
    color: $card_header_btn_color;
  }

  &:active,
  &:focus,
  &:hover {
    background-color: $card_header_btn_hover_bg;
    cursor: pointer;

    .icon:before {
      color: $card_header_btn_hover_color;
    }
  }

  &.active {
    .icon:before {
      color: $card_header_btn_active_color;
    }
  }

  &.pressed {
    background: $card_header_btn_hover_bg;
  }

  &.no-hover:hover {
    background:none ;
  }
}
button.text.round.icon-btn.profiles-btn{
  &:hover{
    background-color:$profiles-app-icon-color;
    .icon:before {
      color: $white;
    }
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 14px;
  margin-bottom: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.24);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: -4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #fac337;
}

input:focus + .slider {
  box-shadow: 0 0 1px #fac337;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input:checked ~ .checkmark {
    background-color: #2196f3;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container .checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
